import React from "react";
import Divider from "../Divider";

const PartnersTestimonial = (props) => {
  const content = props.content;
  return (
    <>
      <div className="outerContainer" style={{ background: "#efeff1" }}>
        <div className="innerContainer">
          <h2 className="testimonial-title">What are our Partners saying</h2>
          <Divider />
          <div className="testimonial-section">
            <div className="testimonial-section-cards">
              {content.map((testimonial, index) => (
                <div className="testimonial-card">
                  <div className="testimonial-card-content">
                    <div className="testimonial-card-logo">
                      <img
                        src={testimonial.logo}
                        alt="user logo"
                        loading="lazy"
                      />
                    </div>
                    <div className="testimonial-card-text para">
                      {testimonial.description}
                    </div>
                    <div className="testimonial-card-name">
                      <h3 className="testimonial-card-name paragraphTitle">
                        {testimonial.name}
                      </h3>
                      <p className="testimonial-card-name-subtitle para">
                        {testimonial.company}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersTestimonial;
