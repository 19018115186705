import React, { useEffect } from "react";
import "./_customerStories.scss";
import Divider from "../../Divider";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";
import { resourcesContent } from "../../resources/resourcesContent";

const CustomerStories = () => {
  // const filteredStories = props.content.filter(
  //   (story) =>
  //     (props.industry === "" &&
  //       !["Healthcare", "BFSI", "Food and Beverage", "Retail"].includes(
  //         story?.industry
  //       ) &&
  //       story?.type !== "Blog") ||
  //     story?.industry === props.industry
  // );

  // const content = filteredStories.slice(0, 3);
  // Slice the first three items
  const latestLibraryItems = resourcesContent.resourceItems.sort(
    (a, b) => parseInt(b.publishedDate) - parseInt(a.publishedDate)
  );

  const content = latestLibraryItems.slice(0, 3);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div
        className="customer-stories-outer-container outerContainer"
        // style={{
        //   backgroundColor: filteredStories.length > 1 ? "#ffffff" : "#f7f7f7",
        // }}
      >
        <div className="customer-stories-inner-container">
          <div className="customer-stories-content">
            <h1 className="customer-stories-title sectionTitle">
              Recommended Resources
            </h1>
            <Divider />
            <div
              className="customer-stories-card-container"
              data-aos="zoom-in-up"
              data-aos-duration="500"
            >
              {content.map((story) => (
                <Tilt
                  tiltEnable={false}
                  glareEnable={true}
                  glareMaxOpacity={0.8}
                  glareColor={"#343e6130"}
                  glarePosition="bottom"
                  glareBorderRadius="20px"
                >
                  <Link to={`/resources/${story.url}`}>
                    <>
                      <div className="customer-stories-card">
                        <div className="customer-stories-card-image">
                          <img
                            src={story.imageURL}
                            alt="customer"
                            loading="lazy"
                            className="customerImage"
                          />
                        </div>
                        <div className="customer-stories-card-content">
                          <h2 className="customer-stories-card-title paragraphTitle">
                            {story.title}
                          </h2>
                        </div>
                      </div>
                    </>
                  </Link>
                </Tilt>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerStories;
