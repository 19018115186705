import { useState } from "react";
import CareerForm from "./form/CareerForm";
import { X } from "lucide-react";

const JobDetails = ({ job, onClose }) => {
  const [modalActive, setModalActive] = useState(false);

  const handleButtonClick = () => {
    setModalActive(true);
  };

  const handleCloseModal = () => {
    setModalActive(false);
  };

  if (!job) return null; // Job details illana render pannadha

  return (
    <>
      {/* Custom Drawer */}
      <div className="custom-drawer">
        <button className="close-button" onClick={onClose}>
          <X />
        </button>
        <div className="drawer-content">
          <h2 className="sectionTitle left margin">{job.jobTitle}</h2>
          <p className="para">{job.JobDescription}</p>
          <p className="para">
            <strong>Job type:</strong> {job.jobType}
          </p>
          <p className="para">
            <strong>Job location:</strong> {job.joblocation}
          </p>
          <div className="drawer-job-details-content">
            <span>Requirements:</span>
            <ul>
              {job.requirements.map((requirement, index) => (
                <li key={index}>{requirement}</li>
              ))}
            </ul>
          </div>
          <div className="drawer-job-details-content">
            <span>Responsibilities:</span>
            <ul>
              {job.responsibilities.map((responsibility, index) => (
                <li key={index}>{responsibility}</li>
              ))}
            </ul>
          </div>
          <button className="button" onClick={handleButtonClick}>
            Apply now!
          </button>
        </div>
      </div>

      {/* Custom Modal */}
      {modalActive && (
        <div className="custom-modal-overlay">
          <div className="custom-modal">
            <button className="form-close-button" onClick={handleCloseModal}>
              <X />
            </button>
            <CareerForm job={job} />
          </div>
        </div>
      )}
    </>
  );
};

export default JobDetails;
