import { ShieldCheck, Link, BarChart3, Headset } from "lucide-react";
export const insightsContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Transform data into insightful visuals instantly to build confidence in AI, drive significant value across your organization, and create a positive impact on your business. Our advanced AI converts complex data into actionable insights through intuitive visualizations. Empower your decision-making with real-time analytics powered by natural language queries and dynamic AI capabilities. ",
    imageURL: "https://cdn.inextlabs.ai/images/products/insights/banner.webp",
    sectionTile: "",
    title: "Transform data into actionable insights with AI",
  },

    valueProps: [
      {
        id: "1",
        model: "model1",
        imageURL: "https://cdn.inextlabs.ai/images/products/insights/proactive-anomaly-detection.webp",

        title: "Proactive anomaly detection across industries",
        mainDescription:
          "Our AI-powered system identifies irregularities before they escalate into major issues. From fraud detection in financial services to predictive maintenance in manufacturing, we ensure that businesses stay ahead by spotting the slightest deviation in patterns. Prevent problems before they impact on your operations and improve performance across sectors. ",
      },
      {
        id: "2",
        model: "model1",
        imageURL: "https://cdn.inextlabs.ai/images/products/insights/natural-language-query.webp",

        title: "Natural language query interface for data insights",
        mainDescription:
          "Unlock complex data insights using simple natural language queries. With our intuitive interface, users can ask questions directly and receive visualized answers instantly, removing the technical barriers to data-driven decision-making.",
      },
      
      {
        id: "3",
        model: "model1",
        imageURL: "https://cdn.inextlabs.ai/images/products/insights/automated-visualizations.webp",

        title: "Automated visualizations for faster analysis",
        mainDescription:
          "Say goodbye to manual data charting. Our AI solution automatically generates visual reports based on raw data, saving time and ensuring you quickly identify key trends, anomalies, and actionable insights, no matter the complexity of the data. ",
      },
      {
        id: "4",
        model: "model1",
        imageURL: "https://cdn.inextlabs.ai/images/products/insights/enhanced-data-interpretation.webp",

        title: "Enhanced data interpretation made easy",
        mainDescription:
          "Visualize critical trends, identify patterns, and uncover anomalies with ease. Our platform simplifies complex datasets, providing enhanced interpretations that allow teams to make smarter, data-backed decisions without needing advanced technical expertise.",
      },
    ],
    whyChooseUs: {
      title: "Why choose us",
      tagline: "It's more than just these reasons",
      content: [
        {
          id: "1",
          title: "Unmatched security",
          tagline:
            "We ensure robust encryption, compliance, and continuous data monitoring.",
          icon: <ShieldCheck size={20} />, // Security & compliance
        },
        {
          id: "2",
          title: "Seamless integration",
          tagline:
            "Easily integrates with IT systems, offering quick deployment, and simplicity.",
          icon: <Link size={20} />, // Represents system connectivity & integration
        },
        {
          id: "3",
          title: "Data-Driven insights",
          tagline:
            "Our AI solutions provide real-time analytics for informed, data-driven decisions.",
          icon: <BarChart3 size={20} />, // Analytics & insights representation
        },
        {
          id: "4",
          title: "Comprehensive support",
          tagline:
            "Enhance communication with humanized, multi-channel, multilingual support for teams.",
          icon: <Headset size={20} />, // Customer support & assistance
        },
      ],
    },
 
  FAQ: [
    {
      id: "1",
      question:
        "1.Why is reporting and analytics important? ",
      answer:
        "Reporting and analytics play a crucial role in modern business operations by enabling informed decision-making, monitoring performance, and identifying trends and patterns. They facilitate resource optimization and enhance communication with stakeholders, while also driving continuous improvement. Furthermore, robust reporting and analytics ensure compliance with regulations and support adherence to industry standards, making them indispensable for effective management and strategic planning.",
    },
    {
      id: "2",
      question: "How do AI reporting and analytics support decision-making? ",
      answer:
        "Reporting and analytics provide data-driven insights that guide decisions. They assess performance, identify opportunities and risks, offer real-time updates, and facilitate scenario analysis. By using objective data and trends, decision-makers can optimize outcomes, capitalize on opportunities, and manage risks effectively. ",
    },
    {
      id: "3",
      question:
        "3. Why Turning Data into Actionable Insights Matters? ",
      answer:
        "Transforming data into actionable insights is vital for businesses as it enables informed decision-making. Amidst a flood of data, extracting valuable insights gives a competitive edge. This approach helps organizations spot opportunities, manage risks, and boost efficiency, ensuring data drives strategic success.",
    },
    {
      id: "4",
      question: "4. How Can Businesses Clearly Convey Data Insights to Their Teams?",
      answer:
        "Effectively sharing data insights means simplifying complex information using visuals like charts and graphs. Presentations should focus on how insights align with company goals. Additionally, engaging team discussions and feedback are essential for ensuring everyone understands the data's importance and how to apply it.",
    },
  ],
  stats: {
    title: "inFlow insights that drive organizational excellence",
    description: "Transform data into actionable visuals for enhanced productivity and satisfaction",
    content: [
      {
        id: "1",
        title: "improvement in productivity",
        count: 80, // Since this is a percentage, you may not have a fixed count.
      },
      {
        id: "2",
        title: "growth in customer satisfaction",
        count: 30, // Similarly, this represents a percentage.
      },
      {
        id: "3",
        title: "better a workflow ",
        count: 44,
      },
      {
        id: "4",
        title: "Operational cost reduction",
        count: 24,
      },
    ],
  },

  meta: {
    title: "inFlow Insights | Transform Data into Actionable Insights",
    description:
      "Use iNextLabs' inFlow Insights to turn your data into easy-to-understand visuals. Make better decisions with real-time analytics and improve your business.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
