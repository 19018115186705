import React, { useRef } from "react";
import Divider from "../Divider";
import "./_partners-logo.scss";
import { motion, useScroll, useTransform } from "framer-motion";

const PartnersLogo = (props) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  return (
    <div>
      <div className="outerContainer partners-logo-outer-container">
        <motion.div
          className="innerContainer partners-logo-inner-container"
          style={{ scale }}
          ref={ref}
        >
          <div className="partners-logo-container">
            <h2 className="sectionTitle">{props.content.title}</h2>
            <Divider />
            <div className="logo">
              {props.content.logos.map((logo) => (
                <img
                  key={logo.id}
                  src={logo.imageURL}
                  alt={logo.alt}
                  loading="lazy"
                />
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default PartnersLogo;
