import React from "react";
import Footer from "../../footer/Footer";
import Meta from "../../Meta";
import Filler from "../../header/Filler";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import { humanresourcesContent } from "./humanResourcesContent";
import Header from "../../header/Header";
import SolutionBanner from "../banner/SolutionBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProposition from "../../value-proposition/ValueProposition";
import AccordionSection from "../../accordion/Accordion";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import { FAQSchema } from "../../schema/FAQSchema";

const HumanResources = () => {
    const faqSchema = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: humanresourcesContent.FAQ.map((item) => ({
        "@type": "Question",
        name: item.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.answer,
        },
      })),
    };
  return (
    <>
          <FAQSchema schemaData={faqSchema} />
    
      <Meta
        title={humanresourcesContent.meta.title}
        description={humanresourcesContent.meta.description}
        image={humanresourcesContent.meta.image}
        locale={humanresourcesContent.meta.locale}
        siteName={humanresourcesContent.meta.siteName}
        type={humanresourcesContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={humanresourcesContent.banner} />
      <CounterStats content={humanresourcesContent.stats} />
      <InnerPageValueProps content={humanresourcesContent.valueProps} />
      <ValueProposition content={humanresourcesContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={humanresourcesContent.FAQ} />
      <Footer />
    </>
  );
};

export default HumanResources;
