import React from "react";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { bankContent } from "./bankContent";
import Meta from "../../Meta";
import SolutionBanner from "../banner/SolutionBanner";
import CustomerStories from "../customer-stories/CustomerStories";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProposition from "../../value-proposition/ValueProposition";
import AccordionSection from "../../accordion/Accordion";
import ValueProps from "../../swimlinesection/ValueProps";
import AssistantSolutions from "../../assistant-solutions/AssistantSolutions";
import Redirect from "../../redirect-page/Redirect";
import Features from "../../features/Features";
import { FAQSchema } from "../../schema/FAQSchema";

const Bank = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: bankContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };

  return (
    <>
      <FAQSchema schemaData={faqSchema} />

      <Meta
        title={bankContent.meta.title}
        description={bankContent.meta.description}
        image={bankContent.meta.image}
        locale={bankContent.meta.locale}
        siteName={bankContent.meta.siteName}
        type={bankContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={bankContent.banner} />
      <CounterStats content={bankContent.stats} />
      <Features content={bankContent.whyChooseUs} />
      <ValueProps content={bankContent.valueProps} />
      <AssistantSolutions content={bankContent.assistantSolutions} />
      <ValueProposition content={bankContent.ValueProposition} />
      <Redirect content={bankContent.redirectPage} />
      <CustomerStories />
      <AccordionSection content={bankContent.FAQ} />
      <Footer />
    </>
  );
};

export default Bank;
