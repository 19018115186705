import React from "react";
import "./_library.scss";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import LibraryDetails from "./LibraryDetails";
import { libraryContent } from "./libraryContent";
import ResouresBanner from "../banner/ResourcesBanner";
import Meta from "../../Meta";

const Library = () => {
  return (
    <>
      <Meta
        title={libraryContent.meta.title}
        description={libraryContent.meta.description}
        image={libraryContent.meta.image}
        locale={libraryContent.meta.locale}
        siteName={libraryContent.meta.siteName}
        type={libraryContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      {/* <HomeBanner content={libraryContent.banner} /> */}
      <ResouresBanner content={libraryContent.banner}/>
      <LibraryDetails />
      <Footer />
    </>
  );
};

export default Library;
