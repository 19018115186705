import React from "react";
import { Clock, FileChartColumnIncreasing, Rss, TvMinimalPlay, UserRound } from "lucide-react";
import dayjs from "dayjs";
function ResourceBanner(props) {
  return (
    <>
      <div className="outerContainer resource-banner">
        <div className="innerContainer">
          <div className="resource-banner-title">{props.content.title}</div>
          <div className="resource-details-items-info">
            <div className="resource-details-items-info-type">
              {props.content.type === "Blog" ? (
                <Rss size={15} />
              ) : props.content.type === "Case study" ? (
                <FileChartColumnIncreasing size={15} />
              ) : (
                <TvMinimalPlay size={15} />
              )}
              {props.content.type}
            </div>
            <div className="resource-details-items-info-date">
              <Clock size={15} />

              {dayjs.unix(props.content.publishedDate).format("MMMM D, YYYY")}
            </div>
            {props.content.author && (
              <div className="resource-details-items-info-author">
                <UserRound size={15} />
                {props.content.author}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ResourceBanner;
