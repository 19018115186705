import {
  BookOpenText,
  BotMessageSquare,
  BrainCircuit,
  Database,
  FilePenLine,
  Layers,
  Lightbulb,
  SearchCheck,
  ShieldCheck,
  Workflow,
} from "lucide-react";
export const generativeAiContent = {
  banner: [
    {
      id: "1",
      primaryButton: {
        text: "Request Demo",
        url: "/contact#contactform",
      },
      secondaryButton: {
        text: "Start Your Free Trial",
        url: "/sign-up",
      },
      description:
        "iNextLabs pioneers Generative AI solutions, transforming enterprise innovation by empowering leaders to evaluate the potential of ideas for practical implementation. Leveraging advanced algorithms and data analytics, our platforms streamline processes, accelerating time-to-market and driving sustainable success in today's dynamic business landscape.",
      imageURL:
        "https://cdn.inextlabs.ai/images/banner-images/bg-banner-image.png",
      title: "Generative AI for your Enterprise",
    },
  ],

  solutions: [
    {
      id: "1",
      title: "ChatGPT-like Virtual Assistant for Enterprises",
      icon: <BotMessageSquare />,
      description1:
        "Deploy a state-of-the-art virtual assistant powered by a vast external knowledge base for both voice and text interactions.",
      description2:
        "Custom-generate responses based on verified and selectively sourced data, and develop tailored educational content for a variety of student needs.",
    },
    {
      id: "2",
      title: "Concise Summaries from Expansive Content",
      icon: <BookOpenText />,
      description1:
        "Distill domain-specific texts into customized, concise overviews that highlight essential insights.",
      description2:
        "Provide succinct summaries of dialogues, insurance details, and meeting notes for quick and effective comprehension.",
    },
    {
      id: "3",
      title: "Customized Content Creation",
      icon: <FilePenLine />,
      description1:
        "Craft purpose-driven textual content to meet your specific communication goals.",
      description2:
        "Innovate your marketing strategies, formulate precise job descriptions, enrich blogs and articles, and streamline email correspondence.",
    },
    {
      id: "4",
      title: "Entity Recognition and Insight Extraction",
      icon: <BrainCircuit />,
      description1:
        "Extract key information from unstructured text to unlock actionable insights and accelerate data processing.",
      description2:
        "Accelerate audit processes and extract critical data points from financial documents such as SEC 10K reports.",
    },
    {
      id: "5",
      title: "Data Exploration and Analytical Insights",
      icon: <SearchCheck />,
      description1:
        "Analyze unstructured data and existing reports to uncover deep insights that can drive strategic decisions.",
      description2:
        "Utilize data visualization tools and develop systems that support decision-making through insightful analysis.",
    },
    {
      id: "6",
      title: "Advanced Data Classification",
      icon: <Layers />,
      description1:
        "Implement sophisticated classification models that can interpret and categorize textual data, starting from zero or minimal examples.",
      description2:
        "Efficiently process customer feedback, classify security threats and vulnerabilities, perform sentiment analysis, and segment customers to enhance business intelligence.",
    },
  ],

  pillers: [
    {
      id: "1",
      icon: <ShieldCheck />,
      title: "Data Governance and Responsible AI Policies",
      description:
        "Ensure successful and compliant outcomes with iNextLabs' responsible AI policies, following the highest data privacy standards and upcoming regulations.",
    },
    {
      id: "2",
      icon: <Database />,
      title: "AI Solutions with Good Data Foundations",
      description:
        "Tailor generative models to your company's needs for content creation, decision-making, and creativity, with adaptable and scalable solutions.",
    },
    {
      id: "3",
      icon: <Lightbulb />,
      title: "Identifying High-Value Use Cases",
      description:
        "Discover the potential of Generative AI with collaborative workshops to identify high-value use cases, build quick prototypes, and take projects from inception to production.",
    },
    {
      id: "4",
      title: "Process Skills and Human-Centric Design",
      icon: <Workflow />,
      description:
        "Seamlessly integrate AI technologies with iNextLabs' focus on operational processes, people skills, and human-centric design, ensuring alignment with organizational goals.",
    },
  ],

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/generative-ai/business-people-working-using-technology-connect-with-others.jpg",
      title: "Effortless Generative AI for Your Company",
      descriptionOne:
        "Seamless Integration: Easily integrate generative AI into workflows with a user-friendly interface, requiring minimal training or coding expertise.",
      descriptionTwo:
        "Customizable Solutions: Tailor generative models to your company's needs for content creation, decision-making, and creativity, with adaptable and scalable solutions.",
      descriptionThree:
        "Scalable Infrastructure: Scale generative AI seamlessly to handle growing workloads, ensuring uninterrupted advanced AI capabilities as your business expands.",
    },

    {
      id: "2",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/generative-ai/person-using-ai-tool-job.jpg",
      title: "Powerful APIs for Generative Applications",
      descriptionOne:
        "Advanced Technology: Harness the power of generative AI through powerful APIs, enabling diverse applications beyond content generation",
      descriptionTwo:
        "Versatility: RAG APIs are designed for many uses, helping developers build smart solutions like question answering systems, chatbots, and language interfaces.",
      descriptionThree:
        "Enhanced Decision Support: Utilize generative AI's advanced capabilities to enhance decision support systems, seamlessly integrating with information retrieval for more informed decision-making.",
    },
    {
      id: "3",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/generative-ai/robot-with-man-suit-talking-man-suit.jpg",
      title: "Take Action with Generative AI",
      descriptionOne:
        "Data-Driven Insights: Transform raw data into actionable insights, uncovering patterns and guiding strategic decisions within your organization.",
      descriptionTwo:
        "Automate Tasks: Free up human resources by automating repetitive tasks, allowing your team to focus on high-value, creative activities while routine tasks are efficiently handled by AI.",
      descriptionThree:
        "Foster Innovation: Spark innovation within your company by using generative AI to generate novel ideas, designs, and solutions, breaking through creative barriers and exploring new possibilities.",
    },
  ],
  meta: {
    title: "Generative AI Solutions for Corporate Business | iNextLabs",
    description:
      "Explore iNextLabs’ generative AI solutions for corporate business, enhancing business efficiency and growth across industries. Most Trusted generative AI solutions.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://cdn.inextlabs.ai/images/og-images/home.png",
    type: "website",
  },
};
