import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProps from "../../swimlinesection/ValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { securityContent } from "./securityContent";
import SolutionBanner from "../../solutions/banner/SolutionBanner";
import { FAQSchema } from "../../schema/FAQSchema";

const Security = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: securityContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };

  return (
    <div>
      <FAQSchema schemaData={faqSchema} />

      <Meta
        title={securityContent.meta.title}
        description={securityContent.meta.description}
        image={securityContent.meta.image}
        locale={securityContent.meta.locale}
        siteName={securityContent.meta.siteName}
        type={securityContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={securityContent.banner} />
      <CounterStats content={securityContent.stats} />
      <ValueProps content={securityContent.valueProps} />
      <ValueProposition content={securityContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={securityContent.FAQ} />
      <Footer />
    </div>
  );
};

export default Security;
