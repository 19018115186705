import React, { useEffect, useState } from "react";
import "./footer.scss";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import NewsLetter from "../newsletter/NewsLetter";
import SignUpModal from "../modal/SignUpModal";
import { freeTrailClickFunction } from "../../app-insights/freeTrailClickFunction";
import {
  Building2,
  Clock,
  Facebook,
  Instagram,
  Linkedin,
  Mail,
  MapPin,
  Phone,
  Twitter,
  Youtube,
} from "lucide-react";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);
  return (
    <>
      <div className="footer-outer-container">
        <div
          className="footer-upper-info-card"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          <div className="footer-upper-card">
            <div className="footer-upper-info-container">
              <div className="footer-upper-info-left">
                <div className="footer-upper-info-content">
                  <h2 className="footer-upper-info-container-title">
                    The Value Providers with Purpose
                  </h2>
                  <p className="footer-upper-info-container-description">
                    Experience the Commitment of a Trusted and Promising
                    Generative AI Platform
                  </p>
                </div>
                <div className="footer-upper-info-container-button">
                  <button
                    className="button"
                    onClick={() => {
                      setIsOpen(true);
                      freeTrailClickFunction();
                    }}
                  >
                    Start Your Free Trial
                  </button>
                </div>
              </div>
              <div className="footer-upper-info-right">
                <img
                  src="https://cdn.inextlabs.ai/images/general/footer-milestone.webp"
                  alt="Company milestone timeline displayed in the footer"
                  id="footer-upper-image"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />

        <div className="footer-inner-container">
          <div className="footer-details-container">
            <div className="footer-company-info-container">
              <div className="footer-company-info-logo">
                <img
                  src="https://cdn.inextlabs.ai/images/general/inextlabs-logo-footer.webp"
                  width={200}
                  alt="inextlabs footer logo"
                  loading="lazy"
                />
              </div>
              <div className="footer-company-info-description">
                We are a Cloud and AI-focused innovative company with a mission
                to bring the power of artificial intelligence to benefit every
                business and individual
              </div>

              <div className="footer-company-info-location">
                <Link
                  to="https://www.google.com/maps/place/iNextLabs+Pte+Ltd/@1.3190985,103.8926941,191m/data=!3m1!1e3!4m6!3m5!1s0x31da1923d651e4f1:0xa974d716d5ea077c!8m2!3d1.3192042!4d103.8926639!16s%2Fg%2F11p0hsfznn?entry=ttu&g_ep=EgoyMDI1MDMwNC4wIKXMDSoASAFQAw%3D%3D"
                  target="_blank"
                  aria-label="Google Maps"
                >
                  <h3 className="font-bold flex gap-2 items-center">
                    <Building2 />
                    Head Office
                  </h3>
                  <p className="ml-4">
                    60 Paya Lebar Road #10-07 Paya Lebar Square Singapore
                    409051.
                  </p>
                </Link>
                <h3 className="font-bold flex gap-2 items-center">
                  <MapPin /> Other Locations
                </h3>
                <p className="ml-4">India | Malaysia | USA</p>
              </div>
              <div className="footer-company-info-contact">
                <div className="footer-company-info-contact-title">Contact</div>
                <div className="footer-company-info-contact-social-media">
                  <Link
                    to="https://www.facebook.com/iNextLabs"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <Facebook />
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/inextlabs"
                    target="_blank"
                    aria-label="Linkedin"
                  >
                    <Linkedin />
                  </Link>

                  <Link
                    to="https://twitter.com/inextlabs"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <Twitter />
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UCJDvWr__3VODxszufzBUHcw"
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <Youtube />
                  </Link>
                  <Link
                    to="https://www.instagram.com/inextlabs/"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <Instagram />
                  </Link>
                </div>

                <div className="footer-company-info-contact-number">
                  <Link
                    to="https://wa.me/6592727331"
                    aria-label="Phone"
                    target="_blank"
                  >
                    <Phone />
                    +6592727331
                  </Link>
                </div>

                <div className="footer-company-info-contact-mail">
                  <Link
                    to="mailto:info@inextlabs.com"
                    aria-label="Mail"
                    target="_blank"
                  >
                    <Mail />
                    info@inextlabs.com
                  </Link>
                </div>
              </div>
              <div className="footer-company-info-support">
                <div className="footer-company-info-support-title">
                  For Support
                </div>
                <div className="footer-company-info-support-mail">
                  <Link
                    to="mailto:support@inextlabs.com"
                    aria-label="Mail"
                    target="_blank"
                  >
                    <Mail />
                    support@inextlabs.com
                  </Link>
                </div>
                <div
                  style={{
                    color: "#fff",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                  className="footer-company-info-support-time"
                >
                  <Clock />
                  <p style={{ margin: 0 }}>
                    Monday to Friday, 9:00 AM to 6:00 PM <br />{" "}
                    <span>(local time except public holidays)</span>
                  </p>
                </div>
              </div>
              <NewsLetter />
            </div>

            <div className="footer-menu-container">
              <div>
                <div className="footer-menu-container-menuTitle">
                  SOLUTIONS - By Industry
                </div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/healthcare"
                  >
                    Healthcare
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/bank"
                  >
                    BFSI
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/restaurant"
                  >
                    Food & Beverage
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/retail"
                  >
                    Retail
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/other-industries"
                  >
                    Other Industries
                  </Link>
                </p>
              </div>

              <div>
                <div className="footer-menu-container-menuTitle">
                  SOLUTIONS - By Function
                </div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/sales-and-marketing"
                  >
                    Sales & Lead Generation
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/customer-support-automation"
                  >
                    Customer Support
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/human-resources"
                  >
                    Human Resources
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/information-technology"
                  >
                    Information Technology
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/solution/procurement"
                  >
                    Procurement
                  </Link>
                </p>
              </div>
              <div>
                <div className="footer-menu-container-menuTitle">COMPANY</div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/about-us"
                  >
                    About Us
                  </Link>
                </p>
                <p>
                  <Link className="footer-menu-container-menuitem" to="/career">
                    Career
                  </Link>
                </p>
                <p>
                  <Link className="footer-menu-container-menuitem" to="/news">
                    Event & News
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/partners"
                  >
                    Partner with Us
                  </Link>
                </p>
              </div>
              <div>
                <div className="footer-menu-container-menuTitle">PRODUCTS</div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/ai-assistants"
                  >
                    inFlow Assistant
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/ai-analyzer"
                  >
                    inFlow Analyzer
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/ai-insights"
                  >
                    inFlow Insights
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/ai-insights"
                  >
                    inFlow Agent Assist
                  </Link>
                </p>
              </div>
              <div>
                <div className="footer-menu-container-menuTitle">
                  PRODUCTS - By Features
                </div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/foundation-model"
                  >
                    Foundation Model
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/security"
                  >
                    Security
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/data-governance"
                  >
                    Data Governance
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/omnichannel"
                  >
                    Omnichannel
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/products/integrations"
                  >
                    Integration
                  </Link>
                </p>
              </div>

              <div>
                <div className="footer-menu-container-menuTitle">RESOURCES</div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/resources/blog"
                  >
                    Blogs
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/resources/casestudy"
                  >
                    Case Studies
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/resources/library"
                  >
                    Library
                  </Link>
                </p>
              </div>
              <div>
                <div className="footer-menu-container-menuTitle">
                  TERMS & POLICIES
                </div>
                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/terms-of-services"
                  >
                    Terms of Use & Service
                  </Link>
                </p>

                <p>
                  <Link
                    className="footer-menu-container-menuitem"
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="footer-copyrights-container">
            Copyright © 2025 iNextLabs - All Rights Reserved
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
