import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "../modal/Modal";

const PartnersForm = () => {
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [response, SetResponse] = useState(null);
  const [errorMessage, SetErrorMessage] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = async (formValues) => {
    setShowModal(true);
    setLoading(true);
    try {
      const response = await fetch("/partner", {
        method: "POST",
        body: JSON.stringify(formValues),
        headers: { "content-type": "application/json" },
      });
      const data = await response.json();

      setLoading(false);

      if (response.ok) {
        SetResponse(data.message);
        setShowResult(true);
        setShowFailure(false);
      } else {
        SetErrorMessage(data.message);
        setShowFailure(true);
        setShowResult(false);
      }
    } catch (error) {
      setLoading(false);
      setShowFailure(true);
      SetErrorMessage(
        " The server encountered an unexpected condition that prevented it from fulfilling the request."
      );
      setShowResult(false);
    }
  };

  return (
    <>
      <div className="outerContainer">
        <div className="partner-form-main-container">
          <div className="partner-form-content-container">
            <h2 className="partners-form-content-container-text bannerTitle">
              Let's Elevate Your Business, Together!
            </h2>
          </div>
          <div className="partner-form-input-container">
            <div id="partners-form-container">
              <h2 className="partners-form-container-title">
                Partnership Enquiry Form
              </h2>
              <form id="partners-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="partners-form-row-container">
                  <div className="partners-form-name">
                    <Controller
                      control={control}
                      name="first_name"
                      defaultValue=""
                      rules={{ required: "First Name is required" }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="row-input"
                          type="text"
                          placeholder="First Name"
                        />
                      )}
                    />
                    {errors.first_name && (
                      <span className="error-message">
                        {errors.first_name.message}
                      </span>
                    )}
                  </div>
                  <div className="partners-form-name">
                    <Controller
                      control={control}
                      name="last_name"
                      defaultValue=""
                      rules={{ required: "Last Name is required" }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="row-input"
                          type="text"
                          placeholder="Last Name"
                        />
                      )}
                    />
                    {errors.last_name && (
                      <span className="error-message">
                        {errors.last_name.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="partners-form-row-container">
                  <div className="partners-email-name">
                    <Controller
                      control={control}
                      name="email_id"
                      defaultValue=""
                      rules={{
                        required: "Email address is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="row-input"
                          type="email"
                          placeholder="Email address"
                        />
                      )}
                    />
                    {errors.email_id && (
                      <span className="error-message">
                        {errors.email_id.message}
                      </span>
                    )}
                  </div>
                  <div className="partners-form-phone">
                    <Controller
                      control={control}
                      name="phone_number"
                      defaultValue=""
                      rules={{
                        required: "Phone number is required",
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Invalid phone number",
                        },
                      }}
                      render={({ field }) => (
                        <input
                          {...field}
                          className="row-input"
                          type="text"
                          placeholder="Phone number"
                        />
                      )}
                    />
                    {errors.phone_number && (
                      <span className="error-message">
                        {errors.phone_number.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="partners-form-row-container">
                  <div className="partners-form-company">
                    <Controller
                      control={control}
                      name="company"
                      defaultValue=""
                      render={({ field }) => (
                        <input
                          {...field}
                          className="row-input"
                          type="text"
                          placeholder="Company"
                        />
                      )}
                    />
                  </div>
                  <div className="partners-form-category">
                    <Controller
                      control={control}
                      name="category"
                      defaultValue="none"
                      rules={{ required: "Category is required" }}
                      render={({ field }) => (
                        <select {...field} id="category" aria-label="country-label">
                          <option value="none">Select Category</option>
                          <option value="Technology Partners">
                            Technology Partners
                          </option>
                          <option value="Channel Partners">
                            Channel Partners
                          </option>
                          <option value="Referral Partners">
                            Referral Partners
                          </option>
                        </select>
                      )}
                    />
                    {errors.category && (
                      <span className="error-message">
                        {errors.category.message}
                      </span>
                    )}
                  </div>
                </div>

                <div
                  className="partners-form-submit-button"
                  style={{ display: "flex" }}
                >
                  <button
                    id="partners-form-submit"
                    className="button"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          loading={loading}
          showResult={showResult}
          showFailure={showFailure}
          closeModal={closeModal}
          response={response}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};

export default PartnersForm;
