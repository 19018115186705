import { Zap, ShieldAlert, BadgeCheck, Lock } from "lucide-react";
export const securityContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "In today’s rapidly evolving digital landscape, ensuring the security and stability of AI deployments across all industries—from HR and retail to healthcare and banking—is paramount. We are committed to safeguarding your AI initiatives by offering comprehensive security solutions that protect your Generative AI models, ensuring they meet the highest ethical standards and perform reliably across diverse applications.",
    imageURL: "https://cdn.inextlabs.ai/images/products/security/banner.webp",
    sectionTile: "",
    title: "Elevating trust and protection in AI projects",
  },

  stats: {
    title: "AI security solutions that protect your digital future",
    description:
      "Protect your AI initiatives with top-tier security and ethical standards",
    content: [
      {
        id: "1",
        title: "Breaches predicted by AI",
        count: 86,
      },
      {
        id: "2",
        title: "Organizations lacking AI security",
        count: 79,
      },
      {
        id: "3",
        title: "Data breaches preventable with AI",
        count: 90,
      },
      {
        id: "4",
        title: "Devices using AI technology today",
        count: 77,
      },
    ],
  },

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/products/security/deploy-ai-with-confidence.webp",
      title: "Deploy AI with confidence",
      mainDescription:
        "Ensure optimal AI performance through precise evaluation, guaranteeing efficient and reliable system operation. Stability is key—monitor and maintain it throughout your AI projects to prevent disruptions and ensure consistent, high-quality outcomes. Continuously track performance to safeguard long-term reliability. Implement strategies that reduce risks and enhance AI outcomes, maximizing the benefits of your deployment. Trust in a seamless, stable AI system that delivers superior results and meets your goals.",
    },
    {
      id: "2",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/products/security/continuous-llm-protection.webp",
      title: "Continuous protection for your LLMs",
      mainDescription:
        "Safeguard your large language models (LLMs) with continuous, real-time monitoring through advanced guardrails that protect against emerging threats. Swift threat detection systems ensure that risks are quickly identified, redacted, and neutralized, maintaining the integrity of your AI models. With real-time response mechanisms in place, vulnerabilities are addressed immediately, ensuring your system remains secure and resilient. These protective strategies allow you to confidently deploy your LLMs, knowing they are secure at all times.",
    },
    {
      id: "3",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/products/security/governance-and-responsibility.webp",
      title: "Governance and responsibility",
      mainDescription:
        "Maintain ethical standards in AI by incorporating strong governance practices into your projects. This ensures safe, responsible AI operation, minimizing the risks of misuse while aligning with regulatory and ethical guidelines. Additionally, strict data privacy measures are embedded in all AI processes, protecting sensitive information and maintaining trust. With this balanced approach to governance, you can confidently deploy AI solutions that are secure, ethical, and aligned with business goals.",
    },
  ],

  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Real-time threat mitigation",
        tagline:
          "Detect and neutralize risks instantly for uninterrupted AI performance.",
        icon: <Zap size={22} />, // Speed & real-time response
      },
      {
        id: "2",
        title: "Adaptive security intelligence",
        tagline: "Evolving protection that learns and adapts to new threats.",
        icon: <ShieldAlert size={20} />, // Adaptive & evolving security
      },
      {
        id: "3",
        title: "Compliance Assurance",
        tagline:
          "Ensure ethical AI operations with seamless regulatory adherence.",
        icon: <BadgeCheck size={22} />, // Compliance & regulatory trust
      },
      {
        id: "4",
        title: "Resilient system protection",
        tagline:
          "Maintain stability and security, even in the face of emerging threats.",
        icon: <Lock size={20} />, // Strong security & system resilience
      },
    ],
  },

  FAQ: [
    {
      id: "1",
      question: "How does AI Security adapt to new threats?",
      answer:
        "Our AI Security uses advanced machine learning algorithms that continuously learn from new data and past incidents. This allows the system to identify emerging threats and update its defenses in real-time, providing proactive protection against evolving risks.",
    },
    {
      id: "2",
      question:
        "What makes AI Security different from traditional cybersecurity?",
      answer:
        "Unlike traditional cybersecurity, which often reacts to known threats, AI Security is proactive. It continuously monitors for unusual patterns and behaviors, making real-time adjustments to prevent potential breaches before they occur. This is especially useful for protecting AI models that require constant updates and fine-tuning.",
    },
    {
      id: "3",
      question: "How does AI Security ensure ethical and responsible AI use?",
      answer:
        "Our AI Security solutions incorporate governance frameworks that monitor for potential misuse or bias in AI models. These frameworks ensure that your AI operates ethically, aligns with regulatory standards, and promotes transparency and fairness in all its outputs.",
    },
    {
      id: "4",
      question: "Will implementing AI Security slow down my AI systems?",
      answer:
        "No, our AI Security is designed to operate seamlessly in the background without affecting the performance of your AI systems. It ensures real-time threat detection and response while maintaining the speed and efficiency of your AI operations.",
    },
  ],

  meta: {
    title: "AI Security Solutions | Protect Your AI Projects with iNextLabs",
    description:
      "Ensure trust and protection in your AI projects with iNextLabs' AI security solutions. Safeguard your AI models across industries like healthcare and banking.",
    locale: "en_US",
    siteName: "iNextLabs",
    image:
      "https://www.inextlabs.com/images/products/security/ai-security-meta.jpg",
    type: "website",
  },
};
