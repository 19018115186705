import React from "react";
import { Link } from "react-router-dom";
import { Clock, FileChartColumnIncreasing, Rss } from "lucide-react";
import dayjs from "dayjs";

const ResourcesItem = (props) => {
  return (
    <>
      <Link
        className="resourceLink"
        to={props.content.url ? `/resources/${props.content.url}` : `/`}
      >
        <div className="resources-item-container">
          <div className="resource-card resource-card-resource">
            <div className="resource-card-image">
              <img
                className="img"
                src={props.content.imageURL}
                alt="resource"
                loading="lazy"
              />
              <div className="resource-industry-country-container">
                {props.content.industry && (
                  <div className="resource-industry">
                    {props.content.industry}
                  </div>
                )}
                {props.content.country && (
                  <div className="resource-country">
                    {props.content.country}{" "}
                  </div>
                )}
              </div>
            </div>
            <div className="resource-content">
              <div className="resource-content-header">
                <p className="resource-category resource-text-success">
                  {props.content.type === "Blog" ? (
                    <Rss size={16} />
                  ) : (
                    <FileChartColumnIncreasing size={16} />
                  )}
                  {props.content.type}
                </p>

                <div className="resource-footer-time">
                  <Clock size={18} />
                  <span>
                    {dayjs
                      .unix(props.content.publishedDate)
                      .format("MMMM D, YYYY")}
                  </span>
                </div>
              </div>
              <div className="resource-card-caption-container">
                <h2 className="resource-card-caption">{props.content.title}</h2>
              </div>
              <div className="resource-card-footer"></div>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ResourcesItem;
