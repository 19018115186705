import React from "react";
import "./_contact.scss";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import CompanyBanner from "../banner/CompanyBanner";
import Footer from "../../footer/Footer";
import { contactContent } from "./contactContent";
import ContactDetails from "./ContactDetails";
import ContactSupport from "./ContactSupport";
import Meta from "../../Meta";

const Contact = () => {
  return (
    <>
      <Meta
        title={contactContent.meta.title}
        description={contactContent.meta.description}
        image={contactContent.meta.image}
        locale={contactContent.meta.locale}
        siteName={contactContent.meta.siteName}
        type={contactContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      {/* <HomeBanner content={contactContent.banner} /> */}
      <CompanyBanner content={contactContent.banner} />
      <div id="contactform" />
      <ContactDetails />
      <ContactSupport content={contactContent.support} />
      <Footer />
    </>
  );
};

export default Contact;
