import React, { useState } from "react";
import "./_webinar.scss";
import Header from "../header/Header";
import Meta from "../Meta";
import Filler from "../header/Filler";
import ResouresBanner from "./banner/ResourcesBanner";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import { webinarContent } from "./webinarContent";
import Redirect from "../redirect-page/Redirect";
import SignUpModal from "../modal/SignUpModal";

const Webinar = () => {
  const upcomingEvents = webinarContent.upcomingEvents;
  const webinars = webinarContent.webinars;
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen
  // const webinarContent = sortedWebinarItems[0]; // Assuming you want the first item for Meta and Banner

  return (
    <div>
      <Meta
        title={webinarContent.meta.title}
        description={webinarContent.meta.description}
        image={webinarContent.meta.image}
        locale={webinarContent.meta.locale}
        siteName={webinarContent.meta.siteName}
        type={webinarContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ResouresBanner content={webinarContent.banner} />
      <Redirect content={webinarContent.featuredEvents} />
      <div className="outerContainer">
        <div className="innerContainer">
          <h1 className="sectionTitle left">Upcoming Events</h1>
          <hr />
          <div className="resourcesListContainer">
            {upcomingEvents.map((webinar, index) => {
              return (
                <UpcomingWebinarItem
                  key={index}
                  content={webinar}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />
              );
            })}
          </div>
          <h1 className="sectionTitle left">Webinars</h1>
          <hr />
          <div className="resourcesListContainer">
            {webinars.map((webinar, index) => {
              return (
                <WebinarItem
                  key={index}
                  content={webinar}
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
      <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};

export default Webinar;

const UpcomingWebinarItem = (props) => {
  return (
    <div className="resources-webinar-item-container">
      <Link
        to={
          props.content.url
            ? `/resources/webinars/${props.content.url}`
            : `/resources/webinars`
        }
        aria-label={props.content.title}
      >
        <div className="resources-webinar-card">
          <div className="resources-webinar-card-image">
            <img src={props.content.imageURL} alt={props.content.title} />
          </div>
          <div className="resources-webinar-card-content">
            <p className="resources-webinar-card-tagline para">
              {props.content.tagline}
            </p>
            <div className="resources-webinar-card-date-time">
              {props.content.date} | {props.content.time}
            </div>
            <button
              className="resources-webinar-card-button"
              onClick={() => props.setIsOpen(true)}
            >
              Get Details
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
};

const WebinarItem = (props) => {
  return (
    <div className="resources-webinar-item-container">
      <Link
        to={
          props.content.url
            ? `/resources/webinars/${props.content.url}`
            : `/resources/webinars`
        }
        aria-label={props.content.title}
      >
        <div className="resources-webinar-card">
          <div className="resources-webinar-card-image">
            <img src={props.content.imageURL} alt={props.content.title} />
          </div>

          <div className="resources-webinar-card-header">
            <p className="resources-webinar-card-header-type">Webinar</p>
            <div className="resources-webinar-card-header-date-time">
              {props.content.date} | {props.content.time}
            </div>
          </div>
          <div className="resources-webinar-card-content">
            <p className="resources-webinar-card-tagline para">
              {props.content.tagline}
            </p>

            <button
              className="resources-webinar-card-button"
              onClick={() => props.setIsOpen(true)}
            >
              {props.content.ctaText}
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
};
