import {
  Users,
  Lightbulb,
  Cpu,
  LineChart,
  BarChart,
  Shield,
  Globe,
  Archive,
  FileText,
  BookOpen,
  Video,
  Briefcase,
  ShoppingBag,
  Monitor,
  Package,
  Heart,
  Coffee,
  Store,
  Globe2,
  UserCheck,
  Phone,
  Calendar,
  Info,
} from "lucide-react";
import React from "react";
import "./_headerMenu.scss";
import { Link } from "react-router-dom";
const HeaderMenu = () => {
  return (
    <div className="headerMenuNav">
      <ul className="navigation">
        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">
            <Link
              to="/generative-ai"
              className="navigation-item-parent-nav-link"
            >
              Generative AI
            </Link>
          </div>
        </li>
        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Products</div>
          <div className="submenu platform-submenu">
            <div className="submenu-content">
              <div className="submenu-box">
                <div className="submenu-box-title">Products</div>
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/ai-assistants"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Lightbulb />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          inFlow Assistant
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Gen AI Powered omnichannel assistants
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/ai-analyzer"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <LineChart />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          inFlow Analyzer
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Comprehensive Analysis for All Your Data Types
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/ai-insights"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <BarChart />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          inFlow Insights
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Transform Data into Insightful Visuals Instantly
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/ai-agent-assist"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Users />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          inFlow Agent Assist
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Elevate Customer Interactions with AI
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="submenu-box">
                <div className="submenu-box-title">Features</div>

                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/foundation-model"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Cpu />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Foundation Model
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Pick the right model for your enterprise
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/security"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Shield />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Security
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Secure and Reliable AI
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/omnichannel"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Globe />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Omnichannel
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Seamlessly Integrated AI for Chat, Voice, and Avatar
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/data-governance"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Archive />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Data Governance
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Master Your Data with Intelligent Governance
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li className="submenu-navigation-item">
                    <Link
                      to="/products/integrations"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Package />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Integrations
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Go live in 1000+ integrations
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>

        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Solutions</div>

          <div className="submenu solutions-submenu">
            <div className="submenu-content">
              <div className="submenu-box">
                <div className="submenu-box-title">By Function</div>
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/information-technology"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Monitor />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">IT</span>
                        <span className="submenu-navigation-subtitle">
                          Enhance IT Efficiency and Effectiveness with AI
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/human-resources"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <UserCheck />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Human Resources
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Enrich and Elevate Employee Experience with AI
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/customer-support-automation"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Phone />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Customer Support
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Smart Al for Superior Customer Support
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/procurement"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <ShoppingBag />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Procurement
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Optimize Procurement with Intelligent AI
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/sales-and-marketing"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <LineChart />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Sales & Lead Generation
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Boost Sales with Al-Powered Solutions
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="submenu-box">
                <div className="submenu-box-title">By Industry</div>

                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/bank"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Briefcase />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">BFSI</span>
                        <span className="submenu-navigation-subtitle">
                          Gen AI solution for Banking & Finance
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/healthcare"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Heart />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Healthcare
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Seamless Communication for Better Healthcare
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/retail"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Store />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">Retail</span>
                        <span className="submenu-navigation-subtitle">
                          Conversational AI solution for Retail industry
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/restaurant"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Coffee />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">F&B</span>
                        <span className="submenu-navigation-subtitle">
                          The dawn of the restaurant industry's future has
                          arrived.
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li className="submenu-navigation-item">
                    <Link
                      to="/solution/other-industries"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Globe2 />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Other Industries
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Innovative AI solutions for every industry
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">
            <Link to="/partners" className="navigation-item-parent-nav-link">
              Partners
            </Link>
          </div>
        </li>
        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Resources</div>
          <div className="submenu resource-submenu">
            <div className="submenu-content">
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/resources/casestudy"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <FileText />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Case Studies
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Success stories and quotes from our customers
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/resources/library"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Archive />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Library
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Latest Brochure & Guide essentials
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link
                      to="/resources/blog"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <BookOpen />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">Blogs</span>
                        <span className="submenu-navigation-subtitle">
                          Informative and inspiring articles from our team
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link
                      to="/resources/webinars"
                      className="submenu-navigation-link"
                    >
                      <div className="submenu-navigation-link-icon">
                        <Video />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">Events</span>
                        <span className="submenu-navigation-subtitle">
                          Events on AI Shaping the Future of Business
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <li className="navigation-item navigation-item--has-submenu">
          <div className="navigation-link">Company</div>
          <div className="submenu company-submenu">
            <div className="submenu-content">
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link to="/about-us" className="submenu-navigation-link">
                      <div className="submenu-navigation-link-icon">
                        <Info />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          About Us
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Our Core Values & Beliefs that supports our Vision &
                          Mission
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link to="/career" className="submenu-navigation-link">
                      <div className="submenu-navigation-link-icon">
                        <Users />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Careers
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Join our team to innovate & grow with us
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="submenu-box">
                <ul className="submenu-navigation">
                  <li className="submenu-navigation-item">
                    <Link to="/news" className="submenu-navigation-link">
                      <div className="submenu-navigation-link-icon">
                        <Calendar />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">News</span>
                        <span className="submenu-navigation-subtitle">
                          Provide up-to-date information on current events,
                          trends, and other news stories.
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li className="submenu-navigation-item">
                    <Link to="/contact" className="submenu-navigation-link">
                      <div className="submenu-navigation-link-icon">
                        <Phone />
                      </div>
                      <div className="submenu-navigation-link-container">
                        <span className="submenu-navigation-title">
                          Contact
                        </span>
                        <span className="submenu-navigation-subtitle">
                          Get in touch with us to enhance your Customer
                          Experience
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HeaderMenu;
