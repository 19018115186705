import React, { useEffect } from "react";
import "./_valueprobs.scss";
import ValuePropsItems from "./ValuePropsItem";
import AOS from "aos";
import "aos/dist/aos.css";
const ValueProps = (props) => {
  const valuePropContent = props.content;
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>
    
      {valuePropContent && (
        <div className="outerContainer inner-value-props-main-container">
          <div className="innerContainer">

            {valuePropContent.map((valueProp, index) => (
              <ValuePropsItems
                layout={index % 2 === 0 ? "Image-Content" : "Content-Image"}
                key={valueProp.id}
                content={valueProp}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ValueProps;
