import React from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import { resourcesContent } from "./resourcesContent";
import ResourceDetailsItems from "./ResourceDetailsItems";
import { useParams } from "react-router-dom"; // Import useParams to get route parameters
import Footer from "../footer/Footer";
import ResourceBanner from "./ResourceBanner";
import { libraryContent } from "./library/libraryContent";
import { ArticleSchema } from "../schema/ArticleSchema";

const ResourceDetails = () => {
  const { id } = useParams();
  const selectedResourceUrl = id;
  const resourceItems = [...resourcesContent.resourceItems];
  const guideItems = libraryContent.libraryItems.filter(
    (item) => item.type === "Guide"
  );
  const updatedResourceItems = [...resourceItems, ...guideItems];

  const selectedResource = updatedResourceItems.find(
    (resource) => resource.url === selectedResourceUrl
  );

  console.log(selectedResource);

  const articleSchema = {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": typeof window !== "undefined" ? window.location.href : "",
    },
    headline: selectedResource?.title,
    image: selectedResource?.imageURL ? [selectedResource.imageURL] : [],
    datePublished: new Date(
      selectedResource?.publishedDate * 1000
    ).toISOString(),
    dateModified: new Date(
      selectedResource?.publishedDate * 1000
    ).toISOString(),
    author: {
      "url": "https://inextlabs.ai",
      "@type": "Person",
      name: selectedResource?.author || "iNextLabs",
    },
    publisher: {
      "@type": "Organization",
      name: "iNextLabs",
      logo: {
        "@type": "ImageObject",
        url: "https://cdn.inextlabs.ai/images/general/inextlabs-logo.webp",
      },
    },
    description: selectedResource?.description || selectedResource?.title,
  };
  return (
    <div>
      <ArticleSchema schemaData={articleSchema} />
      <Header />
      <Filler />
      <ResourceBanner content={selectedResource} />
      <ResourceDetailsItems content={selectedResource} />
      <Footer />
    </div>
  );
};

export default ResourceDetails;
