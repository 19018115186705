import React from 'react'
import Meta from '../../Meta'
import Header from '../../header/Header'
import Filler from '../../header/Filler'
import CounterStats from '../../stats-counter/Stats-counter'
import ValueProps from '../../swimlinesection/ValueProps'
import ValueProposition from '../../value-proposition/ValueProposition'
import DiscoveryWorkshop from '../../discovery-workshop/DiscoveryWorkshop'
import AccordionSection from '../../accordion/Accordion'
import { analyzerContent } from './analyzerContent'
import Footer from '../../footer/Footer'
import SolutionBanner from '../../solutions/banner/SolutionBanner'
import { FAQSchema } from '../../schema/FAQSchema'

const Analyzer = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": analyzerContent.FAQ.map((item) => ({
      "@type": "Question",
      "name": item.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": item.answer,
      },
    })),
  };

  return (
    <div>
      <FAQSchema schemaData={faqSchema}/>
      <Meta
        title={analyzerContent.meta.title}
        description={analyzerContent.meta.description}
        image={analyzerContent.meta.image}
        locale={analyzerContent.meta.locale}
        siteName={analyzerContent.meta.siteName}
        type={analyzerContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={analyzerContent.banner} />
      <CounterStats content={analyzerContent.stats} />
      <ValueProps content={analyzerContent.valueProps} />
      <ValueProposition content={analyzerContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={analyzerContent.FAQ} />
      <Footer />
    </div>
  )
}

export default Analyzer
