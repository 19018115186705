import React, { useRef, useState } from "react";
import "./_value-proposition.scss";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import Divider from "../Divider";
import { ArrowDown } from "lucide-react";

const ValueProposition = (props) => {
  const [showMore, setShowMore] = useState(false); // To manage showing more cards
  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0,
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { x: -50 },
    visible: {
      x: 0,
    },
  };
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  // If showMore is true, show all content; otherwise, show only the first 4 items if length is 4, else show first 3 items
  const visibleContent = showMore
    ? props.content.content
    : props.content.content.length === 4
    ? props.content.content
    : props.content.content.slice(0, 3);

  return (
    <>
      <div className="outerContainer value-proposition-outer-container">
        <motion.div
          className="innerContainer value-proposition-inner-container"
          style={{ scale }}
          ref={ref}
        >
          <div className="value-proposition-wrapper">
            <div className="value-proposition-header">
              <h2 className="value-proposition-header-title sectionTitle">
                {props.content.title}
              </h2>
              <p className="value-proposition-header-tagline para center">
                {props.content.tagline}
              </p>
              <Divider />
            </div>
            <motion.div
              className="value-proposition-content-wrapper"
              style={{
                gridTemplateColumns:
                  visibleContent.length === 4
                    ? "repeat(4, 1fr)"
                    : "repeat(3, 1fr)",
              }}
              ref={ref}
              variants={container}
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
            >
              {visibleContent.map((value) => (
                <motion.div
                  className="value-proposition-content "
                  id={"value-proposition" + value.id}
                  key={value.id}
                  style={value.points && { height: "auto" }}
                  variants={item}
                >
                  <div className="value-proposition-content-icon">
                    {value.icon}
                  </div>
                  <div className="value-proposition-content-title">
                    <h3>{value.title}</h3>
                  </div>
                  <div className="value-proposition-content-description">
                    <p>{value.tagline}</p>
                  </div>
                  {value.points && (
                    <ol>
                      {Object.values(value.points).map((point, index) => (
                        <div
                          className="value-proposition-content-point"
                          key={index}
                        >
                          <li className="para left">{point}</li>
                        </div>
                      ))}
                    </ol>
                  )}
                </motion.div>
              ))}
            </motion.div>

            {/* Show More / Show Less button */}
            {props.content.content.length > 4 && (
              <div className="show-more-button-container">
                <button onClick={handleShowMore} className=" show-more-button">
                  <div className="flex items-center gap-2">
                    <span>{showMore ? "Show Less" : "Show More"}</span>
                    <ArrowDown
                      style={{
                        transform: showMore ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                    />
                  </div>
                </button>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default ValueProposition;