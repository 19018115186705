import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import "./_promoVideo.scss";

const PromoVideo = ({ videoURL, thumbnail }) => {
  const { scrollYProgress } = useScroll();
  const scale = useTransform(scrollYProgress, [0, 0.8], [1, 1.5]);
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry], observer) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(entry.target); // 🔥 Unobserve immediately
        }
      },
      { threshold: 0.3 }
    );

    const currentRef = videoRef.current;
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, []);

  return (
    <div className="promo-video-container">
      <motion.div style={{ scale }} className="innerContainer">
        <video
          ref={videoRef}
          className="promo-video"
          controls
          loop
          poster={thumbnail} // Placeholder image
          preload="metadata"
        >
          {isLoaded && <source src={videoURL} type="video/mp4" />}
        </video>
      </motion.div>
    </div>
  );
};

export default PromoVideo;
