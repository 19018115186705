import React, { useRef } from "react";
import "./_articles.scss";
import ArticlesItem from "./ArticlesItem";
import { newsContent } from "../company/news/newsContent";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { Link } from "react-router-dom";
const Articles = (props) => {
  const ArticlesContent = newsContent.newsItems.slice(1, 4);
  const ArticleLatestContent = newsContent.newsItems.slice(0, 1);

  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { x: 50 },
    visible: {
      x: 0,
    },
  };
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  return (
    <>
      <div className="outerContainer articles-outer-container">
        <motion.div
          className="innerContainer articles-inner-container"
          style={{
            scale,
          }}
          ref={ref}
        >
          <div className="articles-header-container">
            <h2 className="articles-title">Latest News</h2>
          </div>

          <div className="articles-container">
            <div className="articles-left-container">
              <Link to={`/news/${ArticleLatestContent[0].url}`} className="articles-latest-article-link">
                <div className="articles-latest-article">
                  <div className="articles-latest-article-image">
                    <img
                      src={ArticleLatestContent[0].imageURL}
                      alt={ArticleLatestContent[0].altText}
                      loading="lazy"
                    />
                  </div>
                  <div className="articles-latest-article-details">
                    <div className="articles-latest-article-details-type para">
                      News
                    </div>
                    <div className="articles-latest-article-details-date para">
                      {ArticleLatestContent[0].date}
                    </div>
                  </div>
                  <hr style={{ margin: "1rem 0" }} />
                  <div className="articles-latest-article-content">
                    <div className="articles-latest-article-title">
                      <h3>{ArticleLatestContent[0].title}</h3>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <motion.div
              className="articles-right-container"
              variants={container}
              ref={ref}
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
            >
              <div className="articles-list-container">
                {ArticlesContent.map((articles, index) => (
                  <ArticlesItem
                    key={index}
                    content={articles}
                    variants={item}
                  />
                ))}
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Articles;
