import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import SolutionBanner from "../banner/SolutionBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import Footer from "../../footer/Footer";
import { informationTechnologyContent } from "./informationTechnologyContent";
import { FAQSchema } from "../../schema/FAQSchema";

const InformationTechnology = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: informationTechnologyContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };
  return (
    <div>
      <FAQSchema schemaData={faqSchema} />

      <Meta
        title={informationTechnologyContent.meta.title}
        description={informationTechnologyContent.meta.description}
        image={informationTechnologyContent.meta.image}
        locale={informationTechnologyContent.meta.locale}
        siteName={informationTechnologyContent.meta.siteName}
        type={informationTechnologyContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={informationTechnologyContent.banner} />
      <CounterStats content={informationTechnologyContent.stats} />
      <InnerPageValueProps content={informationTechnologyContent.valueProps} />
      <ValueProposition content={informationTechnologyContent.whyChooseUs} />
      {/* <IntegrationSection
        content={informationTechnologyContent.integrations}
      /> */}
      <DiscoveryWorkshop />
      {/* <WebinarSection /> */}
      <AccordionSection content={informationTechnologyContent.FAQ} />
      <Footer />
    </div>
  );
};

export default InformationTechnology;
