import React, { useCallback, useEffect, useState } from "react";
import "./_header.scss";
import { Link } from "react-router-dom";
import HeaderMenu from "./HeaderMenu";
import HeaderMenuBurger from "./HeaderMenuBurger";
import { HashLink } from "react-router-hash-link";
import SignUpModal from "../modal/SignUpModal";
import { freeTrailClickFunction } from "../../app-insights/freeTrailClickFunction";
import { Sparkles } from "lucide-react";
import { motion } from "framer-motion";

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.scrollY;
    setIsVisible(prevScrollPos > currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos]); // ✅ Memoized function avoids re-creation

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]); // ✅ Now it properly tracks handleScroll changes

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <div className={`header ${isVisible ? "" : "header-hidden"}`}>
        <motion.div
          className={`header-top-container ${isVisible ? "" : "header-hidden"}`}
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <div className="header-top-right">
            <div className="header-top-announcement">
              <div className="header-top-announcement-text">
                <Link to="/news/inextlabs-joins-imda's-genai-sandbox-2.0-to-empower-singapore's-smes-with-generativeai">
                  {/* <CiBullhorn size={17} style={{ fontWeight: "bold" }} /> */}
                  <Sparkles size={17} style={{ color: "#f05742" }} />
                  iNextLabs Joins IMDA’s GenAI Sandbox 2.0 to Empower
                  Singapore’s SMEs with Generative AI
                </Link>
              </div>
            </div>
            <div className="header-top-info">
              <div>En</div>
              <div className="header-top-location-dropdown">
                <div
                  className="header-top-location-dropdown-toggle"
                  onClick={toggleDropdown}
                >
                  Singapore
                </div>
                {isDropdownVisible && (
                  <div className="header-top-location-dropdown-menu">
                    <div>Malaysia</div>
                    <div>USA</div>
                    <div>India</div>
                  </div>
                )}
              </div>
              <Link to="https://demo.inextlabs.ai/" target={"_blank"}>
                Partner Login
              </Link>
            </div>
          </div>
        </motion.div>
        <motion.div
          className={`desktopMenu ${isVisible ? "" : "desktopMenu-hidden"}`}
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          <div className="headerContainer">
            <Link to="/">
              <img
                className="headerLogo"
                src="/images/general/inextlabs-logo.webp"
                alt="iNextLabs Official Logo"
                width="176"
                height="44"
              />
            </Link>
            <HeaderMenu />
            <div className="header-buttons">
              <HashLink smooth to="/contact/#contactform">
                <button className="button bannerSecondaryButton">
                  Contact Us
                </button>
              </HashLink>

              <button
                className="button  header-button-two"
                onClick={() => {
                  setIsOpen(true);
                  freeTrailClickFunction();
                }}
              >
                Start Your Free Trial
              </button>
            </div>
          </div>
        </motion.div>

        <div className="burgerMenu">
          <div className="headerContainer">
            <div>
              <Link to="/">
                <img
                  className="headerLogo"
                  src="/images/general/inextlabs-logo.webp"
                  alt="iNextLabs Official Logo"
                />
              </Link>
            </div>
            <div className="burgerMenu">
              <HeaderMenuBurger />
            </div>
          </div>
        </div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default Header;
