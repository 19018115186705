import React, { useState, useEffect, useCallback } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import "./carousel.scss";

export const Caraousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  //   const teamMembers = [
  //     {
  //       name: "Ajisha NV",
  //       role: "Senior System Engineer",
  //       image: "/api/placeholder/400/400",
  //       quote:
  //         "I've been with iNextLabs for over three years now, and as a Senior System Engineer, it's been quite the ride. The tech we work with is super advanced – it's like peeking into the future every day. But what I really love is the team spirit here. We're like a little work family, always helping each other out",
  //     },
  //     {
  //       name: "Sarah Chen",
  //       role: "Product Designer",
  //       image: "/api/placeholder/400/400",
  //       quote:
  //         "Design is about solving problems and creating experiences that delight users. Working with such a talented team pushes me to think creatively every single day.",
  //     },
  //     {
  //       name: "Marcus Rodriguez",
  //       role: "Full Stack Developer",
  //       image: "/api/placeholder/400/400",
  //       quote:
  //         "The best part about our team is how we collaborate across disciplines. Everyone brings their unique perspective to create something amazing.",
  //     },
  //   ];

  const teamMembers = props.content;

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === teamMembers.length - 1 ? 0 : prevIndex + 1
    );
  }, [teamMembers.length]);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? teamMembers.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    let interval;
    if (!isHovered) {
      interval = setInterval(nextSlide, 5000);
    }
    return () => clearInterval(interval);
  }, [isHovered, nextSlide]);

  return (
    <div className="carousel-container">
      <div
        className="carousel"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="carousel__slide">
          <div className="carousel__image-container">
            <img
              src={teamMembers[currentIndex].imageURL}
              alt={teamMembers[currentIndex].name}
              className="carousel__image"
            />
          </div>

          <div className="carousel__content">
            <blockquote className="carousel__quote">
              "{teamMembers[currentIndex].text}"
            </blockquote>
            <div className="carousel__member-info">
              <h3 className="carousel__name">
                {teamMembers[currentIndex].name}
              </h3>
              <p className="carousel__role">
                {teamMembers[currentIndex].designation}
              </p>
            </div>
          </div>
        </div>

        <button
          onClick={prevSlide}
          className="carousel__nav-button carousel__nav-button--prev"
          aria-label="Previous slide"
        >
          <ChevronLeft />
        </button>
        <button
          onClick={nextSlide}
          className="carousel__nav-button carousel__nav-button--next"
          aria-label="Next slide"
        >
          <ChevronRight />
        </button>

        <div className="carousel__dots">
          {teamMembers.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`carousel__dot ${
                index === currentIndex ? "carousel__dot--active" : ""
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
