import React from "react";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { retailContent } from "./retailContent";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import Meta from "../../Meta";
import SolutionBanner from "../banner/SolutionBanner";
import CustomerStories from "../customer-stories/CustomerStories";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProposition from "../../value-proposition/ValueProposition";
import AccordionSection from "../../accordion/Accordion";
import { FAQSchema } from "../../schema/FAQSchema";

const Retail = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: retailContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };
  return (
    <>
      <FAQSchema schemaData={faqSchema} />

      <Meta
        title={retailContent.meta.title}
        description={retailContent.meta.description}
        image={retailContent.meta.image}
        locale={retailContent.meta.locale}
        siteName={retailContent.meta.siteName}
        type={retailContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={retailContent.banner} />
      <CounterStats content={retailContent.stats} />
      <InnerPageValueProps content={retailContent.valueProps} />
      <ValueProposition content={retailContent.whyChooseUs} />
      <CustomerStories />
      {/* <WebinarSection /> */}
      <AccordionSection content={retailContent.FAQ} />
      {/* <PricingTable /> */}

      <Footer />
    </>
  );
};

export default Retail;
