import React from "react";
import "./_location.scss";
import Divider from "../../../Divider";

const Locations = (props) => {
  const locationContent = props.content;
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <h2 className="about-location-section-title sectionTitle">
            Our Location
          </h2>
          <Divider />
          <div className="about-location-container">
            <div className="about-location-container-image">
              <img
                src="https://cdn.inextlabs.ai/images/company/map.svg"
                alt="Map showing the location of InextLabs company."
                loading="lazy"
              />
            </div>
            <div className="about-location-container-list">
              {locationContent.map((location, index) => (
                <div className="about-location-container-list-card" key={index}>
                  <div className="about-location-container-list-card-title paragraphTitle">
                    {location.city}
                  </div>
                  <div className="about-location-container-list-card-address para">
                    <p
                      dangerouslySetInnerHTML={{ __html: location.address }}
                    ></p>
                    {location.addressTwo && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: location.addressTwo,
                        }}
                      ></p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Locations;
