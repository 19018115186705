import { FileText, MessageCircle, Network, Brain } from "lucide-react";
export const restaurentContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Unlock new opportunities with our AI-powered solutions. Increase ROI with personalized recommendations, excellent customer service, and a boost in online orders and sales. Stay ahead by engaging your customers on their preferred platforms.",
    id: "1",
    imageURL: "https://cdn.inextlabs.ai/images/solutions/fnb/banner.webp",
    solutionType: "byIndustry",
    background:
      "https://cdn.inextlabs.ai/images/solutions/healthcare/banner.jpg",
    title: "Maximize F&B Profits with AI Solutions",
  },
  stats: {
    title: "Elevate Customer Satisfaction with AI",
    description:
      "inFlow Assistant transform the F&B industry by automating orders, personalizing recommendations, and handling inquiries, boosting efficiency and satisfaction.",
    content: [
      {
        id: "1",
        title: "Increase in Sales Conversions",
        count: 25,
      },
      {
        id: "2",
        title: "Boost in Customer Loyalty",
        count: 40,
      },
      {
        id: "3",
        title: "Growth in Online Reservations",
        count: 35,
      },
      {
        id: "4",
        title: "Rise in Average Order Value",
        count: 20,
      },
    ],
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/solutions/fnb/rapid-response.webp",
      title: "Streamline Customer Service with Quick Responses",
      descriptionOne:
        "<strong>Fast and Efficient Replies:</strong> Resolve customer issues swiftly.",
      descriptionTwo:
        "<strong>Dedicated to Satisfaction:</strong> Show commitment with timely solutions.",
      descriptionThree:
        "<strong>Build Trust and Reputation:</strong> Enhance loyalty through reliable service.",
    },
    {
      id: "2",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/solutions/fnb/order-taking.webp",
      title: "Expand Your Reach with WhatsApp Broadcasts",
      descriptionOne:
        "<strong>Targeted Promotions:</strong> Boost engagement with personalized offers.",
      descriptionTwo:
        "<strong>Menu & Offer Updates:</strong> Keep customers informed about new dishes and offers.",
      descriptionThree:
        "<strong>Efficient Messaging:</strong> Broadcast to all customers at once.",
    },
    {
      id: "3",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/solutions/fnb/payment-processing.webp",
      title: "Showcase Your Menu for Easy Ordering",
      descriptionOne:
        "<strong>Easy Ordering:</strong> Place orders with just a few clicks.",
      descriptionTwo:
        "<strong>Engaging Digital Menu:</strong> Browse an appealing selection of dishes.",
      descriptionThree:
        "<strong>Personalized Experience:</strong> Enhance satisfaction with tailored menu options.",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/solutions/fnb/analytics.webp",
      title: "Optimize Performance with Advanced Analytics",
      descriptionOne:
        "<strong>Track engagement and sales trends:</strong> Spot growth opportunities.",
      descriptionTwo:
        "<strong>Measure promotion effectiveness:</strong> Refine marketing strategies.",
      descriptionThree:
        "<strong>Understand customer preferences:</strong> Tailor offerings to drive satisfaction.",
    },
  ],
  whyChooseUs: {
    title: "Why Choose Us",
    tagline: "It's More Than Just These Reasons",
    content: [
      {
        id: "1",
        title: "Pre-Built Templates",
        tagline:
          "Ready-made workflows for efficient end-to-end customer journeys in retail.",
        icon: <FileText size={20} />, // Represents document-based templates
      },
      {
        id: "2",
        title: "WhatsApp Commerce",
        tagline:
          "Showcase your menu, automate advance table bookings, orders, and enhance the user journey.",
        icon: <MessageCircle size={20} />, // Represents messaging & WhatsApp commerce
      },
      {
        id: "3",
        title: "Multi-Channel Integration",
        tagline:
          "Access 40+ platforms in 135+ languages via text, voice, and video.",
        icon: <Network size={20} />, // Represents multi-channel connectivity
      },
      {
        id: "4",
        title: "AI Assistance",
        tagline:
          "Boost efficiency, automate tasks, and cut costs with AI-powered conversations.",
        icon: <Brain size={20} />, // Represents AI assistance
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "1. How is AI Transforming the Food Industry?",
      answer:
        "AI boosts efficiency and reduces costs in the food industry. It streamlines production, predicts equipment maintenance, optimizes delivery routes, and minimizes waste. By automating tasks, AI helps lower downtime and ensures better resource management.",
    },
    {
      id: "2",
      question:
        "2. What is the necessity of AI integrated WhatsApp in F&B business?",
      answer:
        "WhatsApp integration allows F&B businesses to connect with customers on a platform they regularly use. This enables real-time communication for orders, support, and personalized service, enhancing customer experience and driving growth.",
    },
    {
      id: "3",
      question:
        "3. How does implementing AI in my F&B business improve customer efficiency?",
      answer:
        "Implementing AI in your F&B business can significantly enhance customer service by providing personalized recommendations, automating order and payment processes, and offering 24/7 support. AI technologies can analyse customer preferences and behaviour to tailor the dining experience, improve operational efficiency, and reduce wait times, leading to higher customer satisfaction and loyalty.",
    },
    {
      id: "4",
      question:
        "4. Are QR scanners safe and reliable for a safe dining experience?",
      answer:
        "As part of the iNextLabs restaurant API, we offer the option to send your customers an ordering link or a QR code for contactless dining. Customers can browse the menu and place their orders conveniently. We understand that some people may feel anxious about scanning QR codes due to potential data breaches. To address this concern, we ensure secure data encryption and are AI Verify Foundation certified, making it impossible for anyone to hack your details while you order your meal.",
    },
  ],

 
  meta: {
    title: "AI Solutions for Restaurants and F&B - iNextLabs",
    description:
      "Leverage AI to elevate your Food & Beverages business, enhance customer engagement, boost sales, and streamline operations, driving innovation and improving efficiency.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
