import React from "react";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import SolutionBanner from "../banner/SolutionBanner";
import { salesAndMarketingContent } from "./SalesAndMarketingContent";
import Footer from "../../footer/Footer";
import AccordionSection from "../../accordion/Accordion";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import ValueProposition from "../../value-proposition/ValueProposition";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import CounterStats from "../../stats-counter/Stats-counter";
import Meta from "../../Meta";
import { FAQSchema } from "../../schema/FAQSchema";

const SalesAndMarketing = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: salesAndMarketingContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };

  return (
    <>
      <FAQSchema schemaData={faqSchema} />

      <Meta
        title={salesAndMarketingContent.meta.title}
        description={salesAndMarketingContent.meta.description}
        image={salesAndMarketingContent.meta.image}
        locale={salesAndMarketingContent.meta.locale}
        siteName={salesAndMarketingContent.meta.siteName}
        type={salesAndMarketingContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={salesAndMarketingContent.banner} />
      <CounterStats content={salesAndMarketingContent.stats} />
      <InnerPageValueProps content={salesAndMarketingContent.valueProps} />
      <ValueProposition content={salesAndMarketingContent.whyChooseUs} />
      <DiscoveryWorkshop />
      {/* <WebinarSection /> */}
      <AccordionSection content={salesAndMarketingContent.FAQ} />
      <Footer />
    </>
  );
};

export default SalesAndMarketing;
