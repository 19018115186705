import React from "react";
import { Clock, Newspaper } from "lucide-react";
function NewsBanner(props) {
  return (
    <>
      <div className="outerContainer resource-banner">
        <div className="innerContainer">
          <div className="resource-banner-title">{props.content.title}</div>
          <div className="resource-banner-info">
            <div className="resource-banner-info-type">
              <Newspaper size={15} />
              {props.content.type}
            </div>
            <div className="resource-banner-info-date">
              <Clock size={15} />
              <span>{props.content.date}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsBanner;
