import React, { useState } from "react";
import { libraryContent } from "./libraryContent";
import { Link } from "react-router-dom";
import Divider from "../../Divider";

const LibraryItems = ({ content }) => {
  const linkTarget =
    content.type === "Guide"
      ? content.url
        ? `/resources/library/${content.url}`
        : `/`
      : content.type === "AI inShorts" || content.type === "Demos"
      ? content.videoURL
      : content.pdfURL;

  return (
    <div className={`${content.type.toLowerCase()}-container`}>
      <div className="library-main-container">
        <div className="library-container">
          <Link
            to={linkTarget}
            target="_blank"
            rel="noopener noreferrer"
            className="library-link"
          >
            <div className="library-image-container">
              <img
                src={content.imageURL}
                alt="library-hero"
                className="library-hero-image"
                loading="lazy"
              />
            </div>
            <div className="library-type">
              <p className="para">{content.type}</p>
            </div>
            <h3 className="library-title paragraphTitle">{content.title}</h3>
          </Link>
        </div>
      </div>
    </div>
  );
};

const LibraryDetails = () => {
  const [selectedTab, setSelectedTab] = useState("Brochure");
  const sortedLibrary = libraryContent.libraryItems.sort(
    (a, b) => b.publishedDate - a.publishedDate
  );
  const libraryTypes = ["Brochure", "AI inShorts", "Demos"];

  const handleTabClick = (type) => {
    setSelectedTab(type);
  };

  const filteredItems = sortedLibrary.filter(
    (item) => item.type.toLowerCase() === selectedTab.toLowerCase()
  );

  return (
    <div className="outerContainer">
      <div className="innerContainer">
        <div className="library-details-header">
          <h2 className="library-details-header-title sectionTitle">
            Resources Library
          </h2>
          <Divider />
          <div className="tabs">
            {libraryTypes.map((type) => (
              <div
                key={type}
                className={`tab-button ${selectedTab === type ? "active" : ""}`}
                onClick={() => handleTabClick(type)}
              >
                {type}
              </div>
            ))}
          </div>
          <div className="library-details-items">
            {filteredItems.map((item, index) => (
              <LibraryItems key={index} content={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LibraryDetails;
