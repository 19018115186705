import React from "react";
import Filler from "../header/Filler";
import HomeBanner from "../banner/HomeBanner";
import { homeContent } from "./HomeContent";
import InnerPageValueProps from "../innerpageswimlane/InnerPageValueProps";
import Header from "../header/Header";
import Articles from "../articles/Articles";
import Footer from "../footer/Footer";
import Meta from "../Meta";
import DiscoveryWorkshop from "../discovery-workshop/DiscoveryWorkshop";
import ValueProposition from "../value-proposition/ValueProposition";
import PartnersLogo from "../partners-logo/PartnersLogo";
import AssistantSolutions from "../assistant-solutions/AssistantSolutions";
import IndustryType from "../industry-types/IndustryType";
import BuisnessStatement from "../statement/BuisnessStatement";
import Redirect from "../redirect-page/Redirect";
import Clients from "../clients/Clients";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="image"
          href="https://cdn.inextlabs.ai/images/home-promo-thumbnail.webp"
          type="image/webp"
        />
      </Helmet>
      <Meta
        title={homeContent.meta.title}
        description={homeContent.meta.description}
        image={homeContent.meta.image}
        locale={homeContent.meta.locale}
        siteName={homeContent.meta.siteName}
        type={homeContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <HomeBanner content={homeContent.banner} />
      <IndustryType content={homeContent.solutionFeatures} />
      <BuisnessStatement content={homeContent.businessValueStatement} />
      {/* <WebinarModal
        isOpen={isWebinarModalOpen}
        setIsOpen={setIsWebinarModalOpen}
      /> */}
      <AssistantSolutions content={homeContent.assistantSolutions} />
      <InnerPageValueProps content={homeContent.valueProps} />
      <Redirect content={homeContent.redirectPage} />
      <ValueProposition content={homeContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <Clients content={homeContent.clients} />
      <PartnersLogo content={homeContent.partners} />
      <Articles content={homeContent.articles} />
      <Footer />
    </>
  );
};

export default Home;
