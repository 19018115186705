import "./_redirect.scss";
import { motion, useScroll, useTransform } from "framer-motion";
import { CalendarDays, Clock } from "lucide-react";
import React, { useRef } from "react";
import { Link } from "react-router-dom";

const Redirect = (props) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <motion.div
            className="redirect-page-container"
            style={{ scale }}
            ref={ref}
          >
            <div className="redirect-page-image">
              <img
                src={props.content.imageURL}
                alt="AI-powered virtual assistant avatars"
                loading="lazy"
              />
            </div>
            <div className="redirect-page-content">
              {props.content.type && (
                <div className="redirect-page-content-type">
                  {props.content.type}
                </div>
              )}
              <h1 className="sectionTitle left">{props.content.title}</h1>
              <p className="para">{props.content.tagline}</p>
              {props.content.date && (
                <div className="redirect-page-content-date-time">
                  <CalendarDays size={18} />
                  {props.content.date}
                </div>
              )}
              {props.content.time && (
                <div className="redirect-page-content-date-time">
                  <Clock size={18} />
                  {props.content.time}
                </div>
              )}
              <div className="redirect-page-button">
                <Link to={props.content.redirectURL}>
                  <button className="button">{props.content.buttonText}</button>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Redirect;
