import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Sparkle } from "lucide-react";

const ValuePropsItems = (props) => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);

  return (
    <>
      <div
        className={
          props.layout === "Content-Image"
            ? "valuePropItemsContainer"
            : "valuePropItemsContainer valuePropItemsContainerReverseDirection"
        }
      >
        <div
          className="valuePropItemsTextOuterContainer"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <div className="valuePropItemsTextInnerContainer">
            <p className="valuePropItemsTextInnerContainerSectionTitle">
              {props.content.sectionTitle}
            </p>
            <h2 className="valuePropItemsTextInnerContainerTitle left">
              {props.content.title}
            </h2>
            <div className="valuePropItemsTextInnerContainerParagraph">
              {props.content.mainDescription}
              <div>{props.content.mainDescriptionOne}</div>
              <div style={{ marginTop: "10px" }}>
                {props.content.mainDescriptionTwo}
              </div>
            </div>
            <div className="valuePropItemsTextInnerSubContainer">
              {props.content.subTitleOne && (
                <div>
                  <h3 className="valuePropItemsTextInnerContainerSubTitle">
                    <Sparkle
                      size={18}
                      style={{ marginRight: "5px", color: "#f05742" }}
                    />
                    {props.content.subTitleOne}
                  </h3>
                  <p className="valuePropItemsTextInnerContainerSubParagraph">
                    {props.content.descriptionOne}
                  </p>
                </div>
              )}
              {props.content.subTitleOne && (
                <div>
                  <h3 className="valuePropItemsTextInnerContainerSubTitle">
                    <Sparkle
                      size={18}
                      style={{ marginRight: "5px", color: "#f05742" }}
                    />
                    {props.content.subTitleTwo}
                  </h3>
                  <p className="valuePropItemsTextInnerContainerSubParagraph">
                    {props.content.descriptionTwo}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className="valuePropItemsImageContainer"
          data-aos="fade-down"
          data-aos-duration="2000"
        >
          <img
            className="valuePropItemsImageContainerImage"
            src={props.content.imageURL}
            alt="value props"
            loading="lazy"
          />
        </div>
      </div>
      <hr style={{ margin: "1rem 0" }} />
    </>
  );
};

export default ValuePropsItems;
