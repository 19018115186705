import { MessagesSquare, Rocket, BarChart3, Workflow } from "lucide-react";
export const CustomerSupportAutomationContent = {
  banner: {
    primaryButton: {
      text: "Start your free trial",
      url: "/contact/#contactform",
    },
    description:
      "AI is revolutionizing customer support by delivering personalized, 24/7 assistance, streamlining routine tasks, and enhancing engagement. AI boosts service quality and operational efficiency, keeping businesses competitive and responsive in a fast-paced environment.",
    id: "1",
    imageURL:
      "https://cdn.inextlabs.ai/images/solutions/customer-support-automation/banner.webp",
    background:
      "https://cdn.inextlabs.ai/images/solutions/customer-support-automation/banner.jpg",
    sectionTile: "",
    title: "Transform your business with AI-driven customer support",
  },
  stats: {
    title: "Experience the impact of AI in customer support",
    description:
      "AI is transforming customer service by enhancing satisfaction, speeding up resolution, and boosting productivity, all while lowering costs.",
    content: [
      {
        id: "1",
        title: "Enhanced customer satisfaction",
        count: 48,
      },
      {
        id: "2",
        title: "Sped-up issue resolution",
        count: 92,
      },
      {
        id: "3",
        title: "Boosted CS specialist's productivity",
        count: 94,
      },
      {
        id: "4",
        title: "Lowered costs due to higher efficiency",
        count: 65,
      },
    ],
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/solutions/customer-support-automation/24_7_availability.webp",
      title: "Enhancing customer support with AI",
      descriptionOne:
        "<strong>24/7 availability:</strong> Provide round-the-clock support to ensure immediate assistance and minimize wait times.",
      descriptionTwo:
        "<strong>Enhanced personalization:</strong> Utilize AI to deliver tailored experiences, boosting customer loyalty and satisfaction.",
      descriptionThree:
        "<strong>Scalability:</strong> Manage interactions efficiently, enabling scalable support without additional costs.",
    },
    {
      id: "2",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/solutions/customer-support-automation/boost_efficiency.webp",
      title: "Boost operational efficiency with AI",
      descriptionOne:
        "<strong>Cost reduction:</strong> Automate routine tasks to reduce costs, handle high volumes, and optimize resources effectively.",
      descriptionTwo:
        "<strong>Scalability:</strong> Easily adjust to fluctuations in customer support demands while maintaining high quality during peak periods.",
      descriptionThree:
        "<strong>Streamlined processes:</strong> Improve service efficiency with AI-driven solutions that manage inquiries, transactions, and updates seamlessly.",
    },
    {
      id: "3",
      model: "model1",
      imageURL:
        "https://cdn.inextlabs.ai/images/solutions/customer-support-automation/future_proof.webp",
      title: "Ensure future-proof customer support",
      descriptionOne:
        "<strong>Continuous improvement:</strong> Benefit from ongoing enhancements as AI solutions learn, evolve, and adapt over time.",
      descriptionTwo:
        "<strong>Proactive engagement:</strong> Use advanced AI to anticipate customer needs, offer proactive support, and enhance overall experiences.",
      descriptionThree:
        "<strong>Integration with technologies:</strong> Seamlessly integrate with new technologies like predictive analytics for more responsive and personalized support.",
    },
  ],
  whyChooseUs: {
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Self-service automation",
        tagline:
          "Resolve queries swiftly with chatbots understanding natural language across channels.",
        icon: <MessagesSquare size={20} />, // Chatbot & self-service automation
      },
      {
        id: "2",
        title: "Supercharged agent productivity",
        tagline:
          "Enhance agent efficiency with suggestions, recommendations, and conversation summaries.",
        icon: <Rocket size={20} />, // Speed & productivity boost
      },
      {
        id: "3",
        title: "AI-backed proactive insights",
        tagline:
          "Monitor efficiency, identify trends, and uncover improvement opportunities with Freddy insights.",
        icon: <BarChart3 size={20} />, // Data insights & analytics
      },
      {
        id: "4",
        title: "Automated workflows",
        tagline:
          "Optimize support with automations, freeing admins for exceptional service.",
        icon: <Workflow size={20} />, // Workflow automation & optimization
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "What does the future hold for AI in customer service?",
      answer:
        "AI is set to transform customer service by handling up to 80% of interactions end-to-end in the next three years. It will enhance efficiency and interaction quality through rapid, personalized, and human-like experiences.",
    },
    {
      id: "2",
      question: "What are some practical use cases of AI in customer service?",
      answer:
        "AI boosts customer service with applications like chatbots, automated routing, sentiment analysis, and fraud prevention. Combined with robotic process automation (rpa), it streamlines processes and ensures accurate, secure responses.",
    },
    {
      id: "3",
      question: "Will AI replace customer service?",
      answer:
        "AI will not replace customer service but will revolutionize it. Over the next three years, AI will manage interactions through intelligent routing, agent support, and automated assistance.",
    },
    {
      id: "4",
      question: "What are some examples of generative AI in customer service?",
      answer:
        "Generative AI includes chatbots, virtual assistants, and automated responses for email and social media. It provides consistent, efficient assistance across various channels.",
    },
  ],
  meta: {
    title: "AI solutions for customer support - inextlabs",
    description:
      "Powered by Generative AI, our platform streamlines customer support, boosts agent productivity, and enhances satisfaction at scale.",
    locale: "en_us",
    siteName: "inextlabs",
    image: "https://cdn.inextlabs.ai/images/og-images/home.png",
    type: "website",
  },
};
