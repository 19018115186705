import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = (props) => {
    const ogImageUrl = `https://inextlabs.ai${props.image}`;
    return (
        
        <Helmet>
            <title>{props.title}</title>
            <meta name="title" content={props.title} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content={props.description} />
            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.description} />
            {props.image ? <meta property="og:image" content={ogImageUrl} /> : <meta property="og:image" content="https://cdn.inextlabs.ai/images/general/homepage_banner.png" />}
            {props.type ? <meta property="og:type" content={props.type} /> : <meta property="og:type" content="website" />}
            {props.siteName ? <meta property="og:site_name" content={props.siteName}/> : <meta property="og:site_name" content="iNextLabs"/>}
            {props.locale ? <meta property="og:locale" content={props.locale}/> : <meta property="og:locale" content="en_US"/>}
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="og:url" content={window.location.pathname+window.location.search}/>
            {props.noindex && <meta name="robots" content="noindex" />}
            <meta name="twitter:image:alt" content="opengraph" />
        </Helmet>
    )
}

export default Meta;