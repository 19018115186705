import { useEffect } from "react";

export const FAQSchema = ({ schemaData }) => {
  useEffect(() => {
    if (!schemaData || !schemaData.mainEntity?.length) {
      console.warn("FAQ Schema data missing or empty!"); // Debug log
      return;
    }

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(schemaData);
    document.head.appendChild(script);

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, [schemaData]);

  return null;
};
