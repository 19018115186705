import React from "react";
import "./_partners.scss";
import Header from "../header/Header";
import Filler from "../header/Filler";
import { partnersContent } from "./partnersContent";
import Footer from "../footer/Footer";
import PartnersLogo from "./PartnersLogo";
import PartnersFeatures from "./PartnersFeatures";
import PartnersTestimonial from "./PartnersTestimonial";
import PartnersType from "./PartnersType";
import PartnersForm from "./PartnersForm";
import Meta from "../Meta";
import CompanyBanner from "../company/banner/CompanyBanner";
const Partners = () => {
  return (
    <>
      <Meta
        title={partnersContent.meta.title}
        description={partnersContent.meta.description}
        image={partnersContent.meta.image}
        locale={partnersContent.meta.locale}
        siteName={partnersContent.meta.siteName}
        type={partnersContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      {/* <HomeBanner content={partnersContent.banner} /> */}
      {/* <PartnersBanner /> */}
      <CompanyBanner content={partnersContent.banner} />
      <PartnersLogo content={partnersContent.partners} />
      <PartnersFeatures content={partnersContent.whyiNextLabsContent} />
      <PartnersTestimonial content={partnersContent.testimonial} />
      <PartnersType content={partnersContent.types} />
      <div id="partnerform" />
      <PartnersForm />
      <Footer />
    </>
  );
};

export default Partners;
