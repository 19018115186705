import React, { useEffect, useRef } from "react";
import "./_stats-counter.scss";
import Divider from "../Divider";
import "aos/dist/aos.css";
import AOS from "aos";

const CounterStats = (props) => {
  const statistics = props.content.content;
  const countingRefs = useRef([]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    countingRefs.current.forEach(({ ref, countTo }) => {
      if (!ref) return;

      const startCount = parseInt(ref.textContent, 10);
      const duration = 2000;
      const startTime = Date.now();

      const updateCount = () => {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const currentCount = Math.floor(
          startCount + progress * (countTo - startCount)
        );

        ref.textContent = currentCount;

        if (progress < 1) {
          requestAnimationFrame(updateCount);
        }
      };

      updateCount();
    });
  }, []);

  return (
    <div
      id="counter-stats"
      className="wow fadeInRight stats-counter-outer-container outerContainer"
      data-wow-duration="1.4s"
      data-aos="zoom-in-up"
    >
      <div className="stats-counter-inner-container">
        <h2 className="stats-counter-title sectionTitle">
          {props.content.title}
        </h2>
        <p className="para center stats-counter-description">
          {props.content.description}
        </p>
        <Divider />
        <div className="stats-counter-row">
          {statistics.map((stat) => {
            return (
              <div
                key={stat.id}
                className="stats-counter"
                id={"stats-counter" + stat.id}
              >
                <i className="fa fa-code" aria-hidden="true"></i>
                <div className="stats-counter-numbers">
                  <div
                    className="stats-counting"
                    data-count={stat.count}
                    ref={(ref) =>
                      countingRefs.current.push({ ref, countTo: stat.count })
                    }
                  >
                    0
                  </div>
                  <div>
                    {stat.countType === "x"
                      ? "x"
                      : stat.countType === "Bn+"
                      ? "Bn+"
                      : "%"}
                  </div>
                </div>
                <h3>{stat.title}</h3>
                {stat.source && (
                  <p className="para stats-counter-source">{stat.source}</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CounterStats;
