import { ShieldCheck, Layers, BarChart2, Users } from "lucide-react";
export const procurementContent = {
  banner: {
    primaryButton: {
      text: "Start your free trial",
      url: "/contact/#contactform",
    },
    description:
      "Artificial intelligence (AI) is transforming procurement by providing deeper insights, automating tasks, and enabling data-driven decision-making. With AI, procurement teams shift from reactive operations to proactive, strategic initiatives that boost efficiency and create value across the entire supply chain.",
    id: "1",
    imageURL: "https://cdn.inextlabs.ai/images/solutions/procurement/banner.webp",
    background: "https://cdn.inextlabs.ai/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    title: "Unlocking the future of procurement",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/solutions/procurement/streamline_operations.webp",
      title: "Streamline procurement operations",
      descriptionOne: `<strong>Manage PR & PO actions:</strong> Automate PR and PO management with AI, allowing real-time status checks for prompt action.`,
      descriptionTwo: `<strong>Simplify invoice approvals:</strong> Utilize AI agents to quickly locate invoice details, resolve mismatches, and approve payments promptly.`,
      descriptionThree: `<strong>Ensure payment timeliness:</strong> Automate invoice tracking and approval to ensure timely vendor payments, reduce delays, and enhance relationships.`,
    },
    {
      id: "2",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/solutions/procurement/optimize_inventory_payment.webp",
      title: "Optimize inventory and payment processes",
      descriptionOne:
        "<strong>Real-time inventory tracking:</strong> Track inventory levels across facilities, trigger low stock alerts to vendors, and ensure timely restocking.",
      descriptionTwo:
        "<strong>Facilitate timely payments:</strong> Track and process invoices efficiently to ensure prompt payments and timely vendor shipments.",
      descriptionThree:
        "<strong>Streamline vendor communication:</strong> Inform vendors of rfps, PO confirmations, and payment statuses to ensure smooth operations.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/solutions/procurement/optimize_shipments_systems.webp",
      title: "Optimize shipments and systems",
      descriptionOne:
        "<strong>Timely shipment notifications:</strong> Notify business operations ahead of inbound shipments to ensure readiness and efficiency.",
      descriptionTwo:
        "<strong>Close the loop on shipments:</strong> Update systems automatically upon receiving shipments to ensure accurate tracking and records.",
      descriptionThree:
        "<strong>Seamless system integration:</strong> Automate workflows to integrate procurement, invoicing, and vendor systems for efficiency.",
    },
  ],

  stats: {
    title: "Experience the impact of autonomous AI",
    description:
      "Transform your procurement operations with our AI-driven solutions, boosting efficiency and creating value across the supply chain.",
    content: [
      {
        id: "1",
        title: "Increase in cost savings",
        count: 40,
      },
      {
        id: "2",
        title: "Time saved in procurement",
        count: 80,
      },
      {
        id: "3",
        title: "Supply chain restructured",
        count: 58,
      },
      {
        id: "4",
        title: "Working hours optimized",
        count: 29,
      },
    ],
  },
  whyChooseUs: {
    title: "Why Choose Us",
    tagline: "It's More Than Just These Reasons",
    content: [
      {
        id: "1",
        title: "Unmatched Security",
        tagline: "We ensure robust encryption, compliance, and continuous data monitoring.",
        icon: <ShieldCheck size={20} />, // Represents security, compliance & protection
      },
      {
        id: "2",
        title: "Seamless Integration",
        tagline: "Easily integrates with IT systems, offering quick deployment, and simplicity.",
        icon: <Layers size={20} />, // Represents IT systems & integration layers
      },
      {
        id: "3",
        title: "Data-Driven Insights",
        tagline: "Our AI solutions provide real-time analytics for informed, data-driven decisions.",
        icon: <BarChart2 size={20} />, // Represents data analytics & AI-driven insights
      },
      {
        id: "4",
        title: "Comprehensive Support",
        tagline: "Enhance communication with humanized, multi-channel, multilingual support for teams.",
        icon: <Users size={20} />, // Represents team collaboration & support
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question: "What do procurement automation solutions offer?",
      answer:
        "Procurement automation solutions simplify purchasing by automating requisitions, purchase orders, invoicing, and vendor management. This reduces manual effort, boosts efficiency, ensures compliance, and provides valuable data insights for improved decision-making.",
    },
    {
      id: "2",
      question: "How is AI transforming procurement?",
      answer:
        "AI in procurement detects issues such as PO delays, unresolved invoices, and payment failures that affect business operations and growth. It identifies non-compliance risks, proposes solutions, and analyzes extensive datasets to deliver actionable insights that enhance efficiency and decision-making.",
    },
    {
      id: "3",
      question: "Which companies use AI in procurement?",
      answer:
        "AI in procurement spans a wide range of applications, making it relevant for companies of all sizes and industries. Adoption rates will grow as more companies recognize the benefits AI brings to procurement.",
    },
    {
      id: "4",
      question: "How can technology enhance PR & PO processes?",
      answer:
        "Technology enhances PR and PO processes by automating tasks, implementing e-procurement systems, centralizing platforms, and employing autonomous agents. These advancements streamline workflows, minimize manual tasks, and ensure efficient approval and order management.",
    },
  ],

  meta: {
    title: "AI solutions for procurement - inextlabs",
    description:
      "Explore AI-powered solutions for procurement at inext labs. Enhance procurement processes, resource management, and operations with advanced technology.",
    locale: "en_us",
    siteName: "inextlabs",
    image: "https://www.inextlabs.com/images/general/procurement_resources.png",
    type: "website",
  },
};