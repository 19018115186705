import { useScroll, motion, useTransform } from "framer-motion";
import React, { useRef } from "react";
import "./_demo-videos.scss";

const DemoVideo = (props) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  return (
    <div>
      <div className="outerContainer">
        <motion.div className="innerContainer" style={{ scale }} ref={ref}>
          <div className="demo-video-container">
            <div className="demo-video-container-left">
              <h2 className="demo-video-title sectionTitle left">
                {props.content.title}
              </h2>
              <p className="demo-video-text para">
                {props.content.description}
              </p>
            </div>
            <div className="demo-video-container-right">
              <iframe
                width="400"
                height="215"
                className="demo-video-iframe"
                src="https://www.youtube.com/embed/2xIXs-gfbQY?si=eNyZJOtIlXjRhMYp"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default DemoVideo;
