import React, { useRef } from "react";

import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { Sparkle } from "lucide-react";

const InnerPageValuePropsItem = (props) => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["end end", "end start"],
  });
  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  const isInView = useInView(targetRef, { once: true });

  return (
    <>
      <motion.div
        style={{ scale }}
        ref={targetRef}
        className={
          props.layout === "Content-Image"
            ? "valuePropItemsContainer"
            : "valuePropItemsContainer valuePropItemsContainerReverseDirection"
        }
      >
        <motion.div
          initial={
            props.layout === "Content-Image"
              ? {
                  x: 50,
                }
              : { x: -50 }
          }
          ref={targetRef}
          animate={
            isInView
              ? {
                  x: 0,
                }
              : {}
          }
          transition={{
            duration: 0.5,
            delay: 0.3,
            type: "spring",
            stiffness: 260,
            ease: "easeOut",
          }}
          className="valuePropItemsTextOuterContainer"
        >
          <div className="valuePropItemsTextInnerContainer">
            <h2 className="valuePropItemsTextInnerContainerSectionTitle">
              {props.content.sectionTitle}
            </h2>
            <p className="valuePropItemsTextInnerContainerTitle left">
              {props.content.title}
            </p>
            <div className="valuePropItemsTextInnerContainerParagraph innerPageValuePropsDescription">
              <div className="valueprops-main-description">
                <p> {props.content.description}</p>
              </div>
              <div className="valueprops-main-description">
                <p> {props.content.description2}</p>
              </div>

              {props.content.model === "model1" ? (
                <div className="valueprops-sub-description">
                  <div className="valueprops-description">
                    <div>
                      <Sparkle
                        size={18}
                        style={{ marginRight: "5px", color: "#f05742" }}
                      />
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.content.descriptionOne,
                      }}
                    ></div>
                  </div>

                  <div className="valueprops-description">
                    <div>
                      <Sparkle
                        size={18}
                        style={{ marginRight: "5px", color: "#f05742" }}
                      />
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.content.descriptionTwo,
                      }}
                    ></div>
                  </div>

                  <div className="valueprops-description">
                    {props.content.descriptionThree && (
                      <div>
                        <Sparkle
                          size={18}
                          style={{ marginRight: "5px", color: "#f05742" }}
                        />
                      </div>
                    )}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: props.content.descriptionThree,
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                <div className="valueprops-sub-description">
                  <div className="valueprops-bychannel-description">
                    <div>
                      <Sparkle
                        size={18}
                        style={{ marginRight: "5px", color: "#f05742" }}
                      />
                      <h4>{props.content.subTitleOne}</h4>
                    </div>
                    <div className="valueprops-bychannel-sub-description">
                      {props.content.descriptionOne}
                    </div>
                  </div>

                  <div className="valueprops-bychannel-description">
                    <div>
                      <Sparkle
                        size={18}
                        style={{ marginRight: "5px", color: "#f05742" }}
                      />
                      <h4>{props.content.subTitleTwo}</h4>
                    </div>
                    <div className="valueprops-bychannel-sub-description">
                      {props.content.descriptionTwo}
                    </div>
                  </div>

                  <div className="valueprops-bychannel-description">
                    {props.content.descriptionThree && (
                      <div>
                        <Sparkle
                          size={18}
                          style={{ marginRight: "5px", color: "#f05742" }}
                        />
                        <h4>{props.content.subTitleThree}</h4>
                      </div>
                    )}
                    <div className="valueprops-bychannel-sub-description">
                      {props.content.descriptionThree}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={
            props.layout === "Content-Image"
              ? {
                  x: 50,
                }
              : { x: -50 }
          }
          ref={targetRef}
          animate={
            isInView
              ? {
                  x: 0,
                }
              : {}
          }
          transition={{
            duration: 0.5,
            delay: 0.3,
            type: "spring",
            stiffness: 260,
            ease: "easeOut",
          }}
          className="valuePropItemsImageContainer"
          data-aos="fade-up-left"
        >
          <img
            className="valuePropItemsImageContainerImage"
            src={props.content.imageURL}
            alt={props.content.title}
            loading="lazy"
          />
        </motion.div>
      </motion.div>
      <hr style={{ margin: "1rem 0" }} />

    </>
  );
};

export default InnerPageValuePropsItem;
