import React, { useEffect, useState } from "react";
import "./_solution-banner.scss";
import "aos/dist/aos.css";
import AOS from "aos";
import SignUpModal from "../../modal/SignUpModal";
import { freeTrailClickFunction } from "../../../app-insights/freeTrailClickFunction";
import { Link } from "react-router-dom";
import { HeroSectionLatestUpdates } from "../../hero-section-updates/HeroSectionLatestUpdates";

const SolutionBanner = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="solution-banner-outer-container outerContainer">
        <div className="solution-banner-inner-bg">
          {props.content.latestUpdates && <HeroSectionLatestUpdates content={props.content.latestUpdates}/>}
          <div className="solution-banner-inner-container">
            <div className="solution-banner-content">
              <h1
                className="solution-banner-title bannerTitle left"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                {props.content.title}
              </h1>
              <p
                className="solution-banner-description para"
                data-aos="fade-right"
                data-aos-duration="700"
              >
                {props.content.description}
              </p>
              <div
                className="solution-banner-button"
                data-aos="fade-right"
                data-aos-duration="900"
              >
                <div>
                  <button
                    className="button solution-banner-primaty-button"
                    onClick={() => {
                      setIsOpen(true);
                      freeTrailClickFunction();
                    }}
                  >
                    {props.content.primaryButton.text}
                  </button>
                  <p className="credit-card-text center">
                    *No credit card required
                  </p>
                </div>
                {props.content.secondaryButton && (
                  <div>
                    <Link
                      to={props.content.secondaryButton.url}
                      target="_blank"
                    >
                      <button className="button bannerSecondaryButton">
                        {props.content.secondaryButton.text}
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="solution-byIndustry-banner-image">
              <img src={props.content.imageURL} alt="" loading="lazy" />
            </div>
          </div>
        </div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default SolutionBanner;
