import { MoveRight } from "lucide-react";
import "./_contactsupport.scss";
import React from "react";
import { Link } from "react-router-dom";

const ContactSupport = (props) => {
  const ContactSupport = props.content;
  const handleSupportClick = () => {
    // Dispatch a custom event instead
    window.dispatchEvent(new Event("handleBotOpen"));
    console.log("Support button clicked");
  };

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="contact-support-list-container">
            {ContactSupport.map((support, index) => (
              <div className="contact-support-list-card" key={index}>
                <div className="contact-support-list-card-title paragraphTitle">
                  {support.title}
                </div>
                <div className="contact-support-list-card-content para">
                  <p>{support.content}</p>
                </div>
                <div className="contact-support-list-card-link para">
                  {support.title !== "24/7 Chat support" ? (
                    <Link to="/">{support.link} </Link>
                  ) : (
                    <button onClick={handleSupportClick}>{support.link}</button>
                  )}
                  <MoveRight size={18} color="#f05742" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSupport;
