import { Clock, Newspaper } from "lucide-react";
import Divider from "../../Divider";
import React from "react";
import { Link } from "react-router-dom";

const NewsItems = (props) => {
  const NewsContent = props.content;
  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <h2 className="news-details-container-title sectionTitle">News</h2>
          <Divider />
          <div className="news-details-container">
            {NewsContent.map((news, index) => (
              <Link
                to={news.url ? `/news/${news.url}` : `/news`}
                className="news-details-items-link"
              >
                <div className="news-details-items" key={index}>
                  <div className="news-details-items-image">
                    <img
                      src={news.imageURL}
                      width={350}
                      alt={news.altText}
                      loading="lazy"
                    />
                  </div>
                  <div className="news-details-items-date-and-type">
                    <div className="news-details-items-type">
                      <Newspaper size={18} style={{ marginRight: "5px" }} />
                      <span>News</span>
                    </div>
                    <div className="news-details-items-date">
                      <Clock size={18} /> <span>{news.date}</span>
                    </div>
                  </div>
                  <div className="news-details-items-title"> {news.title}</div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsItems;
