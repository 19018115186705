import React from "react";

const FeaturesCardItem = (props) => {
  return (
    <>
      <div className={`${props.content.className} features-details-card`}>
        <img src={props.content.imageURL} alt="feature logo" loading="lazy"/>
        {/* <div className="features-details-card-content">
          <div className="features-card-icon">
            <img src={props.content.imageURL} alt="feature logo" width={25} loading="lazy"/>
          </div>
          <div className="features-card-title">{props.content.title}</div>
          <div className="features-card-description">
            {props.content.description}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default FeaturesCardItem;
