import React, { useState } from "react";
import "./_other-industries.scss";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import SolutionBanner from "../banner/SolutionBanner";
import Footer from "../../footer/Footer";
import { otherIndustriesContent } from "./otherIndustriesContent";
import CounterStats from "../../stats-counter/Stats-counter";
import IntegrationSection from "../integration/IntegrationSection";
import CustomerStories from "../customer-stories/CustomerStories";
import AccordionSection from "../../accordion/Accordion";
import Divider from "../../Divider";
import SignUpModal from "../../modal/SignUpModal";
import Meta from "../../Meta";
import { FAQSchema } from "../../schema/FAQSchema";

const OtherIndustries = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: otherIndustriesContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };
  return (
    <>
      <FAQSchema schemaData={faqSchema} />

      <Meta
        title={otherIndustriesContent.meta.title}
        description={otherIndustriesContent.meta.description}
        image={otherIndustriesContent.meta.image}
        locale={otherIndustriesContent.meta.locale}
        siteName={otherIndustriesContent.meta.siteName}
        type={otherIndustriesContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={otherIndustriesContent.banner} />
      <CounterStats content={otherIndustriesContent.stats} />
      <OtherIndustriesSolution content={otherIndustriesContent.industries} />
      <IndustriesInterest />
      <IntegrationSection content={otherIndustriesContent.integrations} />
      <CustomerStories />
      <AccordionSection content={otherIndustriesContent.FAQ} />
      <Footer />
    </>
  );
};

export default OtherIndustries;

const OtherIndustriesSolution = (props) => {
  return (
    <>
      <div className="outerContainer other-industries-solution-outer-container">
        <div className="innerContainer other-industries-solution-inner-container">
          <div className="other-industries-solution-container">
            <div className="other-industries-solution-title">
              <h2 className="sectionTitle">Industries</h2>
              <Divider />
            </div>
            <div className="other-industries-solution-body-container">
              {props.content.map((solution, index) => (
                <div className="other-industries-solution-card">
                  <div className="other-industries-solution-icon">
                    {solution.icon}
                  </div>
                  <div className="other-industries-solution-card-title">
                    <h3 className="paragraphTitle">{solution.title}</h3>
                  </div>
                  <div className="other-industries-solution-card-text para">
                    <p>{solution.descriptionOne}</p>
                    <p>{solution.descriptionTwo}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const IndustriesInterest = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="career-job-interest-outer-container outerContainer">
        <div className="career-job-interest-inner-container innerContainer">
          <h2 class="sectionTitle margin">Don't see your industry listed?</h2>
          <p class="para center">
            Our solutions provide transformative benefits for enterprises across
            all sectors. Our virtual inFlow Assistant are designed to adapt and
            deeply understand any industry's needs. Talk to one of our team
            members to discover how iNextLabs can be tailored to your specific
            requirements.
          </p>
          <button
            className="button"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Start Your Free Trial
          </button>
        </div>
      </div>
      <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
