import React from "react";
import "./hero-section-latest-updates.scss";
export const HeroSectionLatestUpdates = (props) => {
    console.log(props);
    
  return (
    <>
      <div className="hero-section-latest-updates-container">
        <div className="hero-section-latest-updates-upper">
          <img src={props.content.imageURL} alt="" width={100} />
          <div className="hero-section-latest-updates-title">
         {props.content.content}
          </div>
        </div>
      </div>
    </>
  );
};
