import React, { useState } from "react";
import "./_resources-banner.scss";
import SignUpModal from "../../modal/SignUpModal";
import { freeTrailClickFunction } from "../../../app-insights/freeTrailClickFunction";

const ResouresBanner = (props) => {
  const [isOpen, setIsOpen] = useState(false); // Define isOpen and setIsOpen

  return (
    <>
      <div className="resources-banner-outer-container outerContainer">
        <div className="resources-banner-inner-container">
          <div className="resources-banner-content-container">
            <h1 className="bannerTitle">{props.content.title}</h1>
            <p className="para">{props.content.description}</p>
            <div className="resources-banner-content-button">
              <button
                className="button"
                onClick={() => {
                  setIsOpen(true);
                  freeTrailClickFunction();
                }}
              >
                {props.content.primaryButton.text}
              </button>
              <p className="credit-card-text">*No credit card required</p>
            </div>
          </div>
          <div className="resources-banner-image-container">
            <img
              src={props.content.imageURL}
              alt="resources banner"
              loading="lazy"
            />
          </div>
        </div>
        <SignUpModal isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </>
  );
};

export default ResouresBanner;
