import React, { useRef } from "react";
import "./_assistant-solutions.scss";
import Divider from "../Divider";
import { Link } from "react-router-dom";
import { motion, useInView, useScroll, useTransform } from "framer-motion";
import { MoveRight } from "lucide-react";
const AssistantSolutions = (props) => {
  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.2,
        type: "spring",
        ease: "easeInOut",
      },
    },
  };

  const item = {
    hidden: { y: 50 },
    visible: {
      y: 0,
    },
  };
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "end start"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.85]);
  return (
    <>
      <motion.div
        ref={ref}
        className="outerContainer"
        variants={container}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
        <motion.div className="innerContainer" style={{ scale }} ref={ref}>
          <h2 className="sectionTitle">{props.content.title}</h2>
          <p className="para center">{props.content.tagline}</p>
          <Divider />
          <div className="assistant-solutions-card-wrapper">
            {props.content.content.map(
              (solution, index) => (
                (
                  <motion.div
                    className="assistant-solutions-card"
                    variants={item}
                    key={index}
                  >
                    <div className="assistant-solutions-card-image">
                      <img
                        src={solution.imageURL}
                        alt={solution.title}
                        loading="lazy"
                      />
                    </div>
                    <div className="assistant-solutions-card-content">
                      <h2 className="paragraphTitle">{solution.title}</h2>
                      <p className="para">{solution.tagline}</p>
                      {solution.redirectURL && (
                        <div className="assistant-solutions-card-button">
                          <Link to={solution.redirectURL}>
                            <button>
                              Explore
                              <MoveRight />
                            </button>
                          </Link>
                        </div>
                      )}
                    </div>
                  </motion.div>
                )
              )
            )}
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default AssistantSolutions;
