import React, { useState } from "react";
import Header from "../header/Header";
import Filler from "../header/Filler";
import Footer from "../footer/Footer";
import { resourcesContent } from "./resourcesContent";
import ResourcesItem from "./ResourcesItem";
import Meta from "../Meta";
import ResouresBanner from "./banner/ResourcesBanner";
import { ChevronDown, ChevronUp, ListFilter, X } from "lucide-react";

const CaseStudyLists = () => {
  const casestudies = resourcesContent.resourceItems;

  const caseStudyItems = casestudies.filter(
    (caseStudy) => caseStudy.type === "Case study"
  );
  const [isOpenIndustry, setOpenIndustry] = useState(false);
  const [isOpenCountry, setOpenCountry] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const toggleDropdownIndustry = () => {
    setOpenIndustry(!isOpenIndustry);
    setOpenCountry(false);
  };
  const toggleDropdownCountry = () => {
    setOpenCountry(!isOpenCountry);
    setOpenIndustry(false);
  };

  const industries = [
    "Retail",
    "Food and Beverage",
    "Healthcare",
    "BFSI",
    "Others",
  ];
  const countries = ["Singapore", "India", "USA"];

  const handleFilterClick = (filterType, catagory) => {
    let updatedFilters;
    if (filterType === "industry") {
      updatedFilters = selectedIndustries.includes(catagory)
        ? selectedIndustries.filter(
            (selectedIndustry) => selectedIndustry !== catagory
          )
        : [...selectedIndustries, catagory];
      setSelectedIndustries(updatedFilters);
    } else if (filterType === "country") {
      updatedFilters = selectedCountries.includes(catagory)
        ? selectedCountries.filter(
            (selectedCountry) => selectedCountry !== catagory
          )
        : [...selectedCountries, catagory];
      setSelectedCountries(updatedFilters);
    }
  };

  const handleFilterCloseClick = (filterType, category) => {
    if (filterType === "industry") {
      setSelectedIndustries(
        selectedIndustries.filter((industry) => industry !== category)
      );
    } else if (filterType === "country") {
      setSelectedCountries(
        selectedCountries.filter((country) => country !== category)
      );
    }
  };

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="resources-filter-container">
            <div className="resources-filter-title">
              <ListFilter size={24} />
              <p className="para"> Filter by categories</p>
            </div>
            <div className="resources-filter-dropdown-container">
              <div className="resources-filter-dropdown">
                <div
                  className="resources-filter-dropdown-header"
                  onClick={toggleDropdownIndustry}
                >
                  Industry
                  {isOpenIndustry ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </div>
                <div
                  className={`resources-filter-dropdown-body ${
                    isOpenIndustry && "open"
                  }`}
                >
                  {industries.map((industry) => (
                    <div
                      className="resources-filter-dropdown-item"
                      key={industry}
                      onClick={() => handleFilterClick("industry", industry)}
                    >
                      <input
                        type="checkbox"
                        onChange={() => handleFilterClick("industry", industry)}
                        checked={selectedIndustries.includes(industry)}
                        style={{ marginRight: "5px" }}
                      />
                      <label>{industry}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="resources-filter-dropdown">
                <div
                  className="resources-filter-dropdown-header"
                  onClick={toggleDropdownCountry}
                >
                  Country
                  {isOpenCountry ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </div>
                <div
                  className={`resources-filter-dropdown-body ${
                    isOpenCountry && "open"
                  }`}
                >
                  {countries.map((country) => (
                    <div
                      className="resources-filter-dropdown-item"
                      key={country}
                      onClick={() => handleFilterClick("country", country)}
                    >
                      <input
                        type="checkbox"
                        onChange={() => handleFilterClick("country", country)}
                        checked={selectedCountries.includes(country)}
                        style={{ marginRight: "5px" }}
                      />
                      <label>{country}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {(selectedCountries.length > 0 || selectedIndustries.length > 0) && (
            <div className="filtered-list-container">
              <div className="filtered-list">
                {selectedIndustries.map((industry) => (
                  <div className="filtered-list-item" key={industry}>
                    <span>{industry}</span>
                    <X
                      size={16}
                      onClick={() =>
                        handleFilterCloseClick("industry", industry)
                      }
                    />
                  </div>
                ))}
                {selectedCountries.map((country) => (
                  <div className="filtered-list-item" key={country}>
                    <span>{country}</span>
                    <X
                      size={16}
                      onClick={() => handleFilterCloseClick("country", country)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="resourcesListContainer">
            {caseStudyItems
              .filter((casestudy) => {
                if (selectedIndustries.includes("Others")) {
                  const excludedIndustries = [
                    "Healthcare",
                    "BFSI",
                    "Retail",
                    "Food and Beverage",
                  ];
                  return !excludedIndustries.includes(casestudy.industry);
                }
                return (
                  (selectedIndustries.length === 0 ||
                    selectedIndustries.includes(casestudy.industry)) &&
                  (selectedCountries.length === 0 ||
                    selectedCountries.includes(casestudy.country))
                );
              })
              .map((casestudy, index) => (
                <ResourcesItem key={index} content={casestudy} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const CaseStudies = () => {
  const bannerContent = resourcesContent;

  return (
    <>
      <Meta
        title={resourcesContent.metaCaseStudy.title}
        description={resourcesContent.metaCaseStudy.description}
        image={resourcesContent.metaCaseStudy.image}
        locale={resourcesContent.metaCaseStudy.locale}
        siteName={resourcesContent.metaCaseStudy.siteName}
        type={resourcesContent.metaCaseStudy.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <ResouresBanner content={bannerContent.banner} />
      <CaseStudyLists />
      <Footer />
    </>
  );
};

export default CaseStudies;
