export const libraryContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/sign-up",
    },
    description:
      "Explore our comprehensive collection of marketing assets, including brochures, flyers, leaflets, and more. Discover AI-driven insights and solutions tailored to your industry’s needs, empowering your business with the latest in Generative AI technology to optimize operations and drive growth.",
    imageURL:
      "https://cdn.inextlabs.ai/images/resources/library/beautiful-female-executive-working-her-desk.webp",
    subTitle: "",
    title: "Tailored AI Solutions and Resources for Your Industry",
  },

  libraryItems: [
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/voice-assist.webp",
      title: "inFlow Voice Assistant Brochure",
      type: "Brochure",
      pdfURL:
        "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures/inFlow%20Voice%20Assistant%20Brochure.pdf",
      publishedDate: "1719577018",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/avatar-assistant.jpg",
      title: "inFlow Avatar Assistant Brochure",
      type: "Brochure",
      pdfURL:
        "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures/inFlow%20Avatar%20Assistant%20Brochure.pdf",
      publishedDate: "1719577018",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/it-assist.webp",
      title: "IT Assistant",
      type: "Brochure",
      pdfURL:
        "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures/IT%20Assistant.png",
      publishedDate: "1719577018",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/hr-assist.webp",
      title: "HR Assistant",
      type: "Brochure",
      pdfURL:
        "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures/HR%20Assistant.png",
      publishedDate: "1719577018",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/customer-assist.webp",
      title: "Customer Support Assistant",
      type: "Brochure",
      pdfURL:
        "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures/Customer%20Support%20Assistant.png",
      publishedDate: "1719577018",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/inflow.webp",
      title: "inFlow Brochure",
      type: "Brochure",
      pdfURL:
        "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures/inFlow%20Brochure.pdf",
      publishedDate: "1719577018",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/enterprise.webp",
      title: "inFlow Enterprise Brochure",
      type: "Brochure",
      pdfURL:
        "https://inextlabswebassets.blob.core.windows.net/partners-demo-assets-brochures/inFlow%20Enterprise%20Brochure.pdf",
      publishedDate: "1702534239",
    },

    ////////////leaflets////////////

    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/AI inShorts EP 1.webp",
      title: "What makes Generative AI different from traditional AI?",
      type: "AI inShorts",
      videoURL:
        "https://www.youtube.com/watch?v=2rNTwhLaWss&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/AI inShorts EP 2.webp",
      title: "Why Prompting is Crucial When Working with AI",
      type: "AI inShorts",
      videoURL:
        "https://www.youtube.com/watch?v=mTdgerpYfTI&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },

    /////////////////////////// Demos ///////////////////////////

    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/Contacts Module.webp",
      title: "Contacts Module",
      type: "Demos",
      videoURL:
        "https://www.youtube.com/watch?v=pDpvkWTqsZk&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/Response Module.webp",
      title: "Response Module",
      type: "Demos",
      videoURL:
        "https://www.youtube.com/watch?v=e5poe56StbA&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/Broadcast Module.webp",
      title: "Broadcast Module",
      type: "Demos",
      videoURL:
        "https://www.youtube.com/watch?v=e5poe56StbA&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },

    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/Conversation Module.webp",
      title: "Conversation Module",
      type: "Demos",
      videoURL:
        "https://www.youtube.com/watch?v=dOEMf8Ju8xM&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/inFlow for Retail.webp",
      title: "inFlow for Retail",
      type: "Demos",
      videoURL:
        "https://www.youtube.com/watch?v=Sftxb6f1EIA&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/inFlow for Healthcare.webp",
      title: "inFlow for Healthcare",
      type: "Demos",
      videoURL:
        "https://www.youtube.com/watch?v=_jIUB5yHmXk&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },
    {
      imageURL: "https://cdn.inextlabs.ai/images/resources/library/inFlow for f&b.webp",
      title: "inFlow F&B",
      type: "Demos",
      videoURL:
        "https://www.youtube.com/watch?v=e5poe56StbA&ab_channel=iNextLabs",
      publishedDate: "1702534239",
    },
  ],

  meta: {
    title: "AI Resource Library - iNextLabs Library",
    description:
      "Explore our comprehensive AI resource library at iNextLabs. Access brochures, whitepapers, ebooks, articles, and more to stay informed and empowered.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
