import { FileText, MessageCircle, Network, Brain } from "lucide-react";
export const healthcareContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Our AI-powered solution automates outreach, appointments, and reminders, streamlining lead generation, onboarding, and test bookings. This frees up staff to focus on providing exceptional patient care. Enjoy enhanced efficiency and improved health outcomes as staff can dedicate more time to patient care.",
    id: "1",
    imageURL: "https://cdn.inextlabs.ai/images/solutions/healthcare/banner.webp",

    solutionType: "byIndustry",
    background: "https://cdn.inextlabs.ai/images/solutions/healthcare/banner.jpg",
    sectionTile: "",
    mainTitle: "HEALTHCARE",
    title: "Boost Revenue and Scale Operations in Healthcare",
  },
  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/solutions/healthcare/appointment-booking.webp",
      title: "Simplify Appointment Booking",
      descriptionOne:
        "<strong>Easy Booking:</strong> Schedule appointments on WhatsApp.",
      descriptionTwo:
        "<strong>Timely Reminders:</strong> Receive reminders and easy rescheduling options.",
      descriptionThree:
        "<strong>Streamlined Service:</strong> Enhance patient experience with responsive interactions.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/solutions/healthcare/user-friendly-forms.webp",
      title: "Streamline Healthcare with Custom Forms",
      descriptionOne:
        "<strong>Effortless Booking:</strong> Simplify scheduling with intuitive forms.",
      descriptionTwo:
        "<strong>Quick Feedback:</strong> Collect patient insights promptly.",
      descriptionThree:
        "<strong>Accessible Info:</strong> Provide easy access to health resources.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/solutions/healthcare/health-product-catalogs.webp",
      title: "Show catalogs to browse and buy",
      descriptionOne:
        "<strong>Organized Catalogs:</strong> Display products for easy browsing.",
      descriptionTwo:
        "<strong>Hassle-Free Ordering:</strong> Ensure a smooth end-to-end user journey.",
      descriptionThree:
        "<strong>Quick Transactions:</strong> Simplify the buying experience.",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/solutions/healthcare/analytics-monitoring.webp",
      title: "Enhance Services with Streamlined Analytics",
      descriptionOne:
        "<strong>Efficient Management:</strong> Track bookings and inquiries effortlessly.",
      descriptionTwo:
        "<strong>Refined Quality:</strong> Use feedback to improve services.",
      descriptionThree:
        "<strong>Optimized Performance:</strong> Utilize detailed analytics to optimize operations.",
    },
  ],
  whyChooseUs:{
    title: "Why Choose Us",
    tagline: "It's More Than Just These Reasons",
    content: [
      {
        id: "1",
        title: "Pre-Built Templates",
        tagline: "Ready-made workflows for efficient end-to-end customer journeys in retail.",
        icon: <FileText size={20} />, // Represents document-based templates
      },
      {
        id: "2",
        title: "WhatsApp Commerce",
        tagline: "Showcase your menu, automate advance table bookings, orders, and enhance the user journey.",
        icon: <MessageCircle size={20} />, // Represents messaging & WhatsApp commerce
      },
      {
        id: "3",
        title: "Multi-Channel Integration",
        tagline: "Access 40+ platforms in 135+ languages via text, voice, and video.",
        icon: <Network size={20} />, // Represents multi-channel connectivity
      },
      {
        id: "4",
        title: "AI Assistance",
        tagline: "Boost efficiency, automate tasks, and cut costs with AI-powered conversations.",
        icon: <Brain size={20} />, // Represents AI assistance
      },
    ],
  },
  FAQ: [
    {
      id: "1",
      question:
        "1. What are some of the prominent healthcare use cases AI can address?",
      answer:
        "AI can significantly enhance healthcare through various applications, including:<br><strong>Appointment Scheduling:</strong> Automating booking and reminders.<br><strong>Patient Support:</strong> Providing instant responses to common queries.<br><strong>Data Analysis:</strong> Analyzing patient data to predict trends and improve treatments.<br> <strong>Administrative Tasks:</strong> Streamlining tasks like billing and record management.",
    },
    {
      id: "2",
      question: "2. How does AI improve operational efficiency in healthcare?",
      answer:
        "AI enhances operational efficiency in healthcare by automating routine tasks, which reduces manual workload and minimizes errors. It optimizes scheduling by streamlining appointment bookings and resource allocation, ensuring that operations run smoothly. AI also improves data management by facilitating quicker access to and analysis of patient data, leading to more informed decision-making. Moreover, AI enhances communication by enabling faster and more accurate interactions with patients, contributing to an overall more efficient healthcare operation.",
    },
    {
      id: "3",
      question: "3. How do we ensure the privacy of patient information?",
      answer:
        "We prioritize the privacy of patient information through a combination of robust security measures and adherence to industry standards. This includes advanced data encryption to protect sensitive information, strict compliance with regulations such as HIPAA, and rigorous access controls that ensure only authorized personnel can access patient data. Additionally, we conduct regular security audits to identify and address any potential vulnerabilities, ensuring ongoing protection of patient privacy.",
    },
    {
      id: "4",
      question:
        "4. Can I create an experience that combines inFlow Assistant with human agents?",
      answer:
        "Yes, you can create a hybrid experience by integrating inFlow Assistant with human agents. This approach allows:<br><strong>AI for Routine Tasks:</strong> Handling common inquiries and tasks efficiently.<br><strong>Human Agents for Complex Issues:</strong> Providing personalized assistance for more intricate or sensitive matters.<br><strong>Seamless Transition:</strong> Ensuring smooth handoffs between AI and human agents for a cohesive customer experience.",
    },
  ],
  stats: {
    title: "Harness the power of AI in Healthcare Industry",
    description:
      "AI empowers healthcare by delivering instant patient support, streamlining administrative tasks, and enhancing operational efficiency, boosting engagement and driving business growth.",
    content: [
      {
        id: "1",
        title: "reduction in no-shows with automated appointment reminders",
        count: 78, // Since this is a percentage, you may not have a fixed count.
      },
      {
        id: "2",
        title: "of patient queries resolved through self-service",
        count: 80, // Similarly, this represents a percentage.
      },
      {
        id: "3",
        title: "increase in overall patient satisfaction",
        count: 56,
      },
      {
        id: "4",
        title: "prefer digital medication reminders",
        count: 84,
      },
    ],
  },
  meta: {
    title: "AI Solutions for Healthcare - iNextLabs",
    description:
      "Leverage AI to personalize patient care in healthcare, improve engagement, enhance outcomes, and streamline processes, driving innovation and optimizing efficiency.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/resources.png",
    type: "website",
  },
};
