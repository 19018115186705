import React from "react";
import Divider from "../../../Divider";
import "./_teams.scss";
const TeamCard = (props) => {
  const TeamsCardContent = props.content;

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <h2 className="about-teams-section-title sectionTitle">
            Meet the team
          </h2>
          <Divider />
          <div className="about-teams-card-container">
            {TeamsCardContent.map((ourPartner, index) => (
              <div className="about-teams-card" key={index}>
                <div className="about-teams-card-cover">
                  <img
                    className="about-teams-card-avatar"
                    src={ourPartner.avatar}
                    alt={ourPartner.name}
                    loading="lazy"
                  />
                </div>
                <p className="about-teams-card-fullname">{ourPartner.name}</p>
                <p className="about-teams-card-jobtitle">
                  {ourPartner.jobTitle}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCard;
