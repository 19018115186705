import React from "react";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import { healthcareContent } from "./healthcareContent";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import Meta from "../../Meta";
import SolutionBanner from "../banner/SolutionBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProposition from "../../value-proposition/ValueProposition";
import AccordionSection from "../../accordion/Accordion";
import CustomerStories from "../customer-stories/CustomerStories";
import { FAQSchema } from "../../schema/FAQSchema";
const Healthcare = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: healthcareContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };
  return (
    <>
      <FAQSchema schemaData={faqSchema} />

      <Meta
        title={healthcareContent.meta.title}
        description={healthcareContent.meta.description}
        image={healthcareContent.meta.image}
        locale={healthcareContent.meta.locale}
        siteName={healthcareContent.meta.siteName}
        type={healthcareContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      {/* <HomeBanner content={healthcareContent.banner} /> */}
      <SolutionBanner content={healthcareContent.banner} />
      <CounterStats content={healthcareContent.stats} />
      <InnerPageValueProps content={healthcareContent.valueProps} />
      <ValueProposition content={healthcareContent.whyChooseUs} />
      <CustomerStories />
      <AccordionSection content={healthcareContent.FAQ} />
      <Footer />
    </>
  );
};

export default Healthcare;
