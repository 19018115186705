import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContactForm from "./form/ContactForm";
import { Copy } from "lucide-react";

const ContactDetails = () => {
  const [isCopiedPhone, setIsCopiedPhone] = useState(false);
  const [isCopiedEmail, setIsCopiedEmail] = useState(false);

  const handleCopyPhone = () => {
    setIsCopiedPhone(true);
    setTimeout(() => {
      setIsCopiedPhone(false);
    }, 2000); // Display the message for 2 seconds
  };

  const handleCopyEmail = () => {
    setIsCopiedEmail(true);
    setTimeout(() => {
      setIsCopiedEmail(false);
    }, 2000); // Display the message for 2 seconds
  };

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="contact-details-container">
            <div className="contact-details-container-info-content">
              <h2 className="contact-details-container-info-content-title">
                Lets talk!
              </h2>
              <p className="contact-details-container-info-content-description">
                Whether you have questions, feedback, or are eager to explore
                how our innovative solutions can benefit your business, we're
                here to assist. Reach out via the form, email, or phone below,
                and our dedicated team will respond promptly to ensure your
                inquiries are addressed.
              </p>
              <div className="contact-details-container-info-content-card-container">
                <div className="contact-details-container-info-content-card">
                  <div className="contact-card">
                    <p className="contact-card-contentOne">Call us</p>
                    <CopyToClipboard
                      text="+6592727331"
                      onCopy={handleCopyPhone}
                    >
                      <div className="copy-icon-container">
                        <p className="contact-card-contentTwo">+6592727331</p>
                        <Copy className="copy-icon" />
                      </div>
                    </CopyToClipboard>
                    {isCopiedPhone && (
                      <span className="copy-message">Copied!</span>
                    )}
                  </div>
                </div>
                <div className="contact-details-container-info-content-contacts-card">
                  <div className="contact-card">
                    <p className="contact-card-contentOne">Email us</p>
                    <CopyToClipboard
                      text="info@inextlabs.com"
                      onCopy={handleCopyEmail}
                    >
                      <div className="copy-icon-container">
                        <p className="contact-card-contentTwo">
                          info@inextlabs.com
                        </p>
                        <Copy className="copy-icon" />
                      </div>
                    </CopyToClipboard>
                    {isCopiedEmail && (
                      <span className="copy-message">Copied!</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-details-container-form-container">
              <div className="contact-details-container-form-container-main-title sectionMainTitle">
                Get In Touch
              </div>
              <h2 className="contact-details-container-form-container-title">
                Talk to an expert
              </h2>
              <div className="contact-details-container-form-container-description para">
                Interested in discovering how iNextLabs Generative-AI solutions
                can elevate your business? Fill out the form below to connect
                with an expert and explore the possibilities.
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
