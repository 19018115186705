import React from "react";
import Meta from "../../Meta";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import CounterStats from "../../stats-counter/Stats-counter";
import ValueProps from "../../swimlinesection/ValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import { insightsContent } from "./insightsContent";
import SolutionBanner from "../../solutions/banner/SolutionBanner";
import { FAQSchema } from "../../schema/FAQSchema";

const Insights = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: insightsContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };

  return (
    <div>
      <FAQSchema schemaData={faqSchema} />
      <Meta
        title={insightsContent.meta.title}
        description={insightsContent.meta.description}
        image={insightsContent.meta.image}
        locale={insightsContent.meta.locale}
        siteName={insightsContent.meta.siteName}
        type={insightsContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={insightsContent.banner} />
      <CounterStats content={insightsContent.stats} />
      <ValueProps content={insightsContent.valueProps} />
      <ValueProposition content={insightsContent.whyChooseUs} />
      <DiscoveryWorkshop />
      <AccordionSection content={insightsContent.FAQ} />
      <Footer />
    </div>
  );
};

export default Insights;
