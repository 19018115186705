export const webinarContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/webinar-registration",
    },
    description:
      "Join us at live, digital, and hybrid events, as well as webinars, to explore the latest advancements in AI. Stay ahead with insights on AI trends, best practices, and real-world applications while connecting with industry experts and peers.",
    id: "webinar",
    imageURL: "https://cdn.inextlabs.ai/images/resources/webinar/webinar-banner.webp",
    title: "Global Events on AI to Meet Us Live or Virtually",
  },
  featuredEvents: {
    type: "Featured Event",
    date: "Wednesday, April 30",
    time: "1 - 5pm SGT",
    title: "Al-Powered Transformation: The Future of Business with AI",
    tagline:
      "An in-person roadshow event for all Singapore businesses to leverage the power of Al and be a part of the IMDA Sandbox 2.0 program initiative.",
    imageURL: "https://cdn.inextlabs.ai/images/resources/webinar/roadshow-poster.webp",
    redirectURL: "https://www.eventbrite.com/e/ai-powered-transformation-the-future-of-business-with-ai-tickets-1255317872549?aff=oddtdtcreator",
    buttonText: "Register Now",
  },
  upcomingEvents: [
    {
      date: "August 22, 2025",
      time: "10:00 AM – 1:00 PM MYT",
      title: "Agentic AI: The Next Evolution of Generative AI",
      imageURL:
        "https://cdn.inextlabs.ai/images/resources/webinar/agentic-ai.webp",
      tagline:
        "This event delves into the advancements in Agentic AI, exploring its transformative potential in automating complex tasks and enhancing business efficiency. Participants will gain insights into integrating Agentic AI within their organizations to drive innovation and productivity.",
      location: "Kuala Lumpur, Malaysia",
      url: "",
    },
    {
      date: "September 19, 2025",
      time: "2:00 PM – 5:00 PM GST",
      title: "Contact Center Modernization: AI-Driven Customer Engagement",
      imageURL:
        "https://cdn.inextlabs.ai/images/resources/webinar/contact-center-modernization.webp",
      tagline:
        "Customer expectations are evolving, and AI is at the core of transforming contact centers. Join us to discover how AI-powered automation, sentiment analysis, and conversational AI are reshaping customer service, improving response times, and enhancing operational efficiency.",
      location: "Dubai, UAE",
      url: "",
    },
    {
      date: "October 10, 2025",
      time: "9:30 AM – 12:30 PM SGT",
      title: "Document Intelligence: Automating Data Extraction and Insights",
      imageURL:
        "https://cdn.inextlabs.ai/images/resources/webinar/document-intelligence.webp",
      tagline:
        "Customer expectations are evolving, and AI is at the core of transforming contact centers. Join us to discover how AI-powered automation, sentiment analysis, and conversational AI are reshaping customer service, improving response times, and enhancing operational efficiency.",
      location: "Singapore",
      url: "",
    },
  ],

  webinars: [
    {
      date: "January 18, 2025",
      time: "3:00 PM – 4:30 PM SGT",
      title: "AI Agent Assist A Must Have Tool for Contact Centers",
      imageURL:
        "https://cdn.inextlabs.ai/images/resources/webinar/ai-agent-assist.webp",
      tagline:
        "This session explored how AI Agent Assist enhances contact center efficiency by providing real-time suggestions, automating workflows, and ensuring seamless omnichannel support.",
      url: "",
      ctaText: "Know More",
    },
    {
      date: "March 8, 2025",
      time: "2:00 PM – 3:30 PM SGT",
      title: "How AI Transforms Document Processing in BFSI",
      imageURL:
        "https://cdn.inextlabs.ai/images/resources/webinar/document-ai.webp",
      tagline:
        "Industry experts discussed how AI-driven document intelligence automates processing, extracts critical data, and ensures compliance, significantly reducing manual workload and turnaround time.",
      url: "",
      ctaText: "Know More",
    },
    {
      date: "August 22, 2025",
      time: "11:00 AM – 12:30 PM SGT",
      title: "Enhancing IT Support and Customer Service with AI in BFSI",
      imageURL:
        "https://cdn.inextlabs.ai/images/resources/webinar/it-support-ai.webp",
      tagline:
        "AI-driven IT support is revolutionizing BFSI by providing real-time troubleshooting, automating service requests, and enhancing customer self-service. This session will explore how AI minimizes downtime and streamlines banking operations.",
      url: "",
      ctaText: "Know More",
    },
    {
      date: "September 10, 2025",
      time: "4:00 PM – 5:30 PM SGT",
      title: "How AI and Automation are Transforming the HR Industry",
      imageURL: "https://cdn.inextlabs.ai/images/resources/webinar/hr-ai.webp",
      tagline:
        "Discover how AI is optimizing recruitment, onboarding, and employee engagement, enabling HR teams to reduce manual workload, improve retention, and create a data-driven workforce strategy.",
      url: "",
      ctaText: "Know More",
    },
    {
      date: "October 5, 2025",
      time: "1:00 PM – 2:30 PM SGT",
      title: "The Power of Voice AI in Business Operations",
      imageURL:
        "https://cdn.inextlabs.ai/images/resources/webinar/voice-ai.webp",
      tagline:
        "Voice AI is transforming how businesses interact with customers, automate processes, and improve accessibility. Learn how AI-powered voice assistants enhance customer support, hands-free operations, and real-time communication.",
      url: "",
      ctaText: "Know More",
    },
    {
      date: "November 15, 2025",
      time: "5:00 PM – 6:30 PM SGT",
      title: "The Future of AI Agent Assist and its Impact on Business Growth",
      imageURL:
        "https://cdn.inextlabs.ai/images/resources/webinar/future-ai-agent.webp",
      tagline:
        "AI-powered assistants are now essential for workflow automation, decision-making, and customer engagement. This session will explore emerging trends, challenges, and strategies for integrating AI Agent Assist into various industries.",
      url: "",
      ctaText: "Know More",
    },
  ],
  meta: {
    title: "Latest Webinars on AI across industries",
    description:
      "Join our AI webinars at iNextLabs to gain insights from industry experts. Stay updated with the latest trends and advancements in artificial intelligence.",
    locale: "en_US",
    siteName: "iNextLabs",
    image: "https://www.inextlabs.com/images/general/webinar.png",
    type: "website",
  },
};
