import { BrainCircuit, BadgeCheck, Puzzle, ShieldCheck } from "lucide-react";
export const omnichannelContent = {
  banner: {
    primaryButton: {
      text: "Start Your Free Trial",
      url: "/contact/#contactform",
    },
    description:
      "Address your business needs with iNextLabs’ Gen AI, seamlessly integrating across Chat, Voice, and Avatar channels. Our omnichannel solutions unify your existing systems to deliver a consistent and effective customer experience. Enhance communication, boost operational efficiency, and connect with your workforce wherever they are. Transform interactions and achieve impactful results with our versatile AI technology.",
    imageURL: "https://cdn.inextlabs.ai/images/products/omnichannel/banner.webp",
    sectionTile: "",
    title: "Seamlessly connect everywhere with omnichannel AI",
  },

  stats: {
    title: "Unlock the power of omnichannel strategies",
    description:
      "Maximize retention, resolve queries, and boost value with seamless integration",
    content: [
      {
        id: "1",
        count: 89,
        title:
          "retain customers with strong omnichannel strategies, compared to 33with weak engagement.",
      },
      {
        id: "2",
        count: 49,
        title: "resolve doubts and queries with AI-powered assistants.",
      },
      {
        id: "3",
        count: 30,
        title: "higher customer lifetime value with omnichannel strategies.",
      },
      {
        id: "4",
        count: 20,
        title:
          "higher conversion rates with omnichannel versus single-channel strategies.",
      },
    ],
  },

  valueProps: [
    {
      id: "1",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/products/omnichannel/24-7-support.webp",
      title: "24/7 multichannel support",
      mainDescription:
        "Our Omnichannel AI offers nonstop support across Chat, Voice, and Avatar channels. Available around the clock, it ensures your business can always engage with customers and resolve issues, boosting satisfaction and trust. Your AI is ready to provide quick and efficient responses any time of day or night.",
    },
    {
      id: "2",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/products/omnichannel/multilingual-communication.webp",
      title: "Multilingual communication",
      mainDescription:
        "Our AI’s multilingual capabilities break down language barriers, supporting a wide range of languages. This feature ensures seamless, personalized interactions for a global audience, enhancing accessibility and expanding market reach. Communicating in customers' preferred languages strengthens relationships and engagement.",
    },
    {
      id: "3",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/products/omnichannel/enhanced-interaction.webp",
      title: "Enhanced customer interaction",
      mainDescription:
        "Elevate customer engagement with our AI’s personalized and dynamic interactions across Chat, Voice, and Avatar channels. Tailoring responses to individual preferences improves satisfaction and retention. Integrated channels ensure consistent and impactful interactions.",
    },
    {
      id: "4",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/products/omnichannel/scalable-flexible-solution.webp",
      title: "Scalable and flexible solution",
      mainDescription:
        "Our AI solution scales with your business, adapting to growth and new features effortlessly. It integrates smoothly with existing systems, handling increased demand without sacrificing performance. This flexibility prepares you for future challenges and opportunities.",
    },
    {
      id: "5",
      model: "model1",
      imageURL: "https://cdn.inextlabs.ai/images/products/omnichannel/unified-experience.webp",
      title: "Unified experience and insights",
      mainDescription:
        "Create a seamless customer journey with a unified platform for all communication channels. Our AI offers real-time insights from Chat, Voice, and Avatar interactions, driving data-informed decisions and service improvements. This approach enhances the customer experience and streamlines operations.",
    },
  ],

  whyChooseUs:{
    title: "Why choose us",
    tagline: "It's more than just these reasons",
    content: [
      {
        id: "1",
        title: "Gen AI powered",
        tagline: "Harness the future of AI technology.",
        icon: <BrainCircuit size={20} />, // AI & intelligence representation
      },
      {
        id: "2",
        title: "Proven ROI",
        tagline: "Achieve tangible results and value.",
        icon: <BadgeCheck size={20} />, // ROI & trust validation
      },
      {
        id: "3",
        title: "Seamless integration",
        tagline: "Connect effortlessly with your existing systems.",
        icon: <Puzzle size={20} />, // Integration & connectivity
      },
      {
        id: "4",
        title: "Advanced security",
        tagline: "Protect your data with top-tier security.",
        icon: <ShieldCheck size={20} />, // Security & data protection
      },
    ],
  },

  FAQ: [
    {
      id: "1",
      question: "What does 'omnichannel' mean in the context of AI solutions?",
      answer:
        "In the context of AI solutions, 'omnichannel' refers to the integration of various communication channels—such as Chat, Voice, and Avatar—into a unified platform. This approach ensures that customer interactions are seamless and consistent across all channels, providing a cohesive and comprehensive experience regardless of how or where customers engage with your business.",
    },
    {
      id: "2",
      question: "How does omnichannel AI improve customer experience?",
      answer:
        "Omnichannel AI enhances customer experience by ensuring that interactions are consistent and personalized across all touchpoints. Whether customers reach out via Chat, Voice, or Avatar, they receive a unified experience that remembers their previous interactions and preferences, leading to more relevant and efficient service.",
    },
    {
      id: "3",
      question:
        "What are the benefits of integrating multiple channels into a single AI platform?",
      answer:
        "Integrating multiple channels into a single AI platform offers several benefits, including a consistent customer experience, improved efficiency, and streamlined operations. It allows for real-time data sharing across channels, which helps in providing timely and accurate responses. This integration also reduces the need for customers to repeat information and enables a more personalized approach to service.",
    },
    {
      id: "4",
      question:
        "How does omnichannel AI handle data across different communication channels?",
      answer:
        "Omnichannel AI consolidates data from various communication channels into a single system, allowing for a comprehensive view of customer interactions. This centralized approach ensures that all relevant information is available to AI systems and human agents alike, enabling more informed and cohesive responses and interactions across all channels.",
    },
  ],

  meta: {
    title:
      "Omnichannel AI Solutions for Seamless Connectivity | iNextLabs",
    description:
      "Experience seamless connectivity with iNextLabs' omnichannel AI solutions. Integrate chat, voice, and avatar channels for a unified customer experience.",
    locale: "en_US",
    siteName: "iNextLabs",
    image:
      "https://www.inextlabs.com/images/products/omnichannel-ai/meta-omnichannel-ai.jpg",
    type: "website",
  },
};
