import React, { useEffect } from "react";
import "./_modal.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { X } from "lucide-react";

const Modal = ({
  loading,
  showResult,
  showFailure,
  closeModal,
  errorMessage,
  response,
}) => {
  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);
  return (
    <>
      <div className="modal-container" data-aos="zoom-in">
        {loading ? (
          <div class="custom-loader"></div>
        ) : (
          <>
            {showResult && (
              <div className="modal-content">
                <div className="model-content-header">
                  <X
                    onClick={closeModal}
                    className="modal-content-header-close"
                    size={20}
                  />

                  <img
                    src="https://cdn.inextlabs.ai/images/general/check.svg"
                    alt="check"
                    width={80}
                    data-aos="zoom-out-down"
                    loading="lazy"
                  />
                </div>

                <h3 className="modal-content-title">Success</h3>
                <p className="para modal-content-message">{response}</p>
                {/* 
                <button className="modal-content-btn" onClick={closeModal}>
                  Close
                </button> */}
              </div>
            )}
            {showFailure && (
              <div className="modal-content">
                <div className="model-content-header">
                  <img
                    src="https://cdn.inextlabs.ai/images/general/cancel.svg"
                    alt="cancel"
                    width={80}
                    loading="lazy"
                    data-aos="zoom-out-down"
                  />
                </div>

                <h3 className="modal-content-title-fail">oops !</h3>
                <p className="para modal-content-message">{errorMessage}</p>

                <button className="modal-content-btn-fail" onClick={closeModal}>
                  Try Again
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Modal;
