import React from "react";
import Header from "../../header/Header";
import Filler from "../../header/Filler";
import Footer from "../../footer/Footer";
import SolutionBanner from "../banner/SolutionBanner";
import CounterStats from "../../stats-counter/Stats-counter";
import InnerPageValueProps from "../../innerpageswimlane/InnerPageValueProps";
import ValueProposition from "../../value-proposition/ValueProposition";
import DiscoveryWorkshop from "../../discovery-workshop/DiscoveryWorkshop";
import AccordionSection from "../../accordion/Accordion";
import { CustomerSupportAutomationContent } from "./customerSupportAutomationContent";
import Meta from "../../Meta";
import { FAQSchema } from "../../schema/FAQSchema";

const CustomerSupportAutomation = () => {
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: CustomerSupportAutomationContent.FAQ.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  };

  return (
    <>
      <FAQSchema schemaData={faqSchema} />

      <Meta
        title={CustomerSupportAutomationContent.meta.title}
        description={CustomerSupportAutomationContent.meta.description}
        image={CustomerSupportAutomationContent.meta.image}
        locale={CustomerSupportAutomationContent.meta.locale}
        siteName={CustomerSupportAutomationContent.meta.siteName}
        type={CustomerSupportAutomationContent.meta.type}
        noindex={false}
      />
      <Header />
      <Filler />
      <SolutionBanner content={CustomerSupportAutomationContent.banner} />
      <CounterStats content={CustomerSupportAutomationContent.stats} />
      <InnerPageValueProps
        content={CustomerSupportAutomationContent.valueProps}
      />
      <ValueProposition
        content={CustomerSupportAutomationContent.whyChooseUs}
      />

      <DiscoveryWorkshop />
      {/* <WebinarSection /> */}
      <AccordionSection content={CustomerSupportAutomationContent.FAQ} />
      <Footer />
    </>
  );
};

export default CustomerSupportAutomation;
