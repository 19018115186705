import React from "react";
import "slick-carousel/slick/slick.css";
import "./_clients.scss";
import "slick-carousel/slick/slick-theme.css";
import Divider from "../Divider";

const Clients = (props) => {
  const images = props.content;
  const midIndex = Math.ceil(images.length / 2);
  const firstHalf = images.slice(0, midIndex);
  const secondHalf = images.slice(midIndex);

  return (
    <div className="clients-container outerContainer">
      <h2 className="clients-container-title">
        Trusted By Companies Worldwide
      </h2>
      <Divider />
      <ImageSlider images={firstHalf} width={120} height={120} />
      <ImageSlider images={secondHalf} width={120} height={120} reverse />
    </div>
  );
};

export default Clients;
const ImageSlider = ({ images, width, height, reverse }) => {
  return (
    <div
      className={`slider ${reverse ? "reverse" : ""}`}
      style={{
        "--width": `${width}px`,
        "--height": `${height}px`,
      }}
    >
      <div className="list">
        {images.map((src, index) => (
          <div className="item" key={index}>
            <img src={src} alt={`Client logo ${index + 1}`} loading="lazy" />
          </div>
        ))}
      </div>
    </div>
  );
};
