import React, { useEffect } from "react";
import AOS from "aos";
import "./_features.scss";
import "aos/dist/aos.css";
import FeaturesCardItem from "./FeaturesCardItem";

const Features = (props) => {
  const FeaturesContent = props.content.features;
  console.log(FeaturesContent);

  useEffect(() => {
    AOS.init({
      duration: 500,
      once: false,
    });
  }, []);
  const content = props.content.content;

  return (
    <>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="features-container">
            <div className="features-details-wrapper">
              <div
                className="features-details-container"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                {FeaturesContent.map(
                  (card, index) => (
                    (<FeaturesCardItem key={index} content={card} />)
                  )
                )}
              </div>
            </div>
            <div
              className="features-content-container"
              data-aos="fade-right"
              data-aos-duration="3000"
            >
              {/* <div className="features-content-section-title">Features</div> */}
              <h2 className="features-content-title">{props.content.title}</h2>
              <div className="features-content-description">
                {props.content.tagline}
              </div>
              <div className="features-content-items">
                {content.map((item, index) => (
                  <div className="why-choose-us-content-list" key={index}>
                    <img
                      src="https://cdn.inextlabs.ai/images/icons/check-mark.svg"
                      alt="pointer"
                      width={15}
                      loading="lazy"
                    />
                    <p className="para">{item.title}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
